import {FilterSearchSkeleton} from "@/components/atoms/skeletons/filter-search-skeleton";
import {useLoading} from "@/hooks/useLoading";
import {useStarTypesByInterestList} from "@/hooks/useStarTypesByInterestList";
import {IList} from "@/interfaces";
import {Autocomplete, Chip, TextField} from "@mui/material";
import React, {memo, useCallback, useEffect, useState} from "react";

interface StarTypeSearchSelectProps {
  types?: number[];
  interests: number[];
  country: number;
  onStarTypeChange: (types: IList[]) => void;
	disabled?: boolean;
}

export const StarTypeSearch: React.FC<StarTypeSearchSelectProps> = memo((
	{
		 onStarTypeChange,
		 types,
		 interests,
		 country,
		disabled
	 }) => {

	const [selectedStarTypes, setSelectedStarTypes] = useState<IList[]>([]);
	const {starTypesList, loading} = useStarTypesByInterestList(country, interests);
	const loadingSkeleton = useLoading(loading, <FilterSearchSkeleton/>);

    useEffect(() => {
      if (types && types.length > 0) {
        const typesList = starTypesList.filter((type) =>
          types.includes(type.id)
        );
        setSelectedStarTypes(typesList);
      }
    }, [types, starTypesList]);

    const handleTypeChange = useCallback(
      (event: React.SyntheticEvent, value: IList[]) => {
        setSelectedStarTypes(value);
        onStarTypeChange(value);
      },
      [onStarTypeChange]
    );

    if (loading) return loadingSkeleton;

    return (
      <Autocomplete
        size="small"
        multiple
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        groupBy={(option) => option.group}
        options={starTypesList}
        value={selectedStarTypes}
        onChange={handleTypeChange}
				disabled={disabled}
        renderInput={(params) => (
          <TextField {...params} label="Account Type" variant="filled" />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option.name}
              size="small"
              color="primary"
              {...getTagProps({ index })}
            />
          ))
        }
      />
    );
  }
);
