import {getAudiences} from "@/api/audience";
import {IAudience} from "@/interfaces";
import {useAuth0} from "@auth0/auth0-react";
import React, {ReactNode, useContext, useEffect, useReducer, useState} from 'react';
import {audienceReducer} from './audience-reducer';
import {Action} from "./types";

interface AudienceState {
	audiences: IAudience[];
	isLoading: boolean;
	error: string | null;
}

export const AudienceContext = React.createContext<{
	state: AudienceState;
	dispatch: React.Dispatch<Action>;
}>({
	state: {} as AudienceState,
	dispatch: () => null,
});

interface AudienceProviderProps {
	children: ReactNode;
	initialState: IAudience[];
}

export const AudienceProvider: React.FC<AudienceProviderProps> = ({children, initialState}) => {
	const [state, dispatch] = useReducer(audienceReducer, initialState);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const {getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		const fetchData = async () => {
			const accessToken = await getAccessTokenSilently();
			try {
				const {data} = await getAudiences(accessToken);
				dispatch({type: 'SET_AUDIENCE', payload: data});
				setIsLoading(false);
			} catch (err: any) {
				setIsLoading(false);
				setError(err.message);
			}
		};

		fetchData();
	}, []);

	return (
		<AudienceContext.Provider value={{state: {audiences: state, isLoading, error}, dispatch}}>
			{children}
		</AudienceContext.Provider>
	);
};

export const useAudience = () => {
	const context = useContext(AudienceContext);
	if (context === undefined) {
		throw new Error('useAudience must be used within an AudienceProvider');
	}
	return context;
};
