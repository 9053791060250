import React, { FC } from "react";

import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Cake, Male, Female, PeopleAlt, Person } from "@mui/icons-material";

import { InsightCard, Tooltip } from "@/components";
import { abbreviateNumber, formatPercentage, titleCase } from "@/helpers";
import { IScore } from "@/interfaces";

interface InsightCardDemogProps {
  audienceSize: number;
  modelledSize: number;
  demographics: IScore[];
}

export const InsightCardDemog: FC<InsightCardDemogProps> = ({
  audienceSize,
  modelledSize,
  demographics,
}) => {
  const topAge = demographics
    .filter((score) => score.type === "Age")
    .reduce((prev, current) => {
      return prev.index > current.index ? prev : current;
    });
  const topGender = demographics
    .filter((score) => score.type === "Gender")
    .reduce((prev, current) => {
      return prev.penetration > current.penetration ? prev : current;
    });

  return (
    <InsightCard title={"Who are my Audience?"}>
      <Grid container height="100%">
        <Grid xs={12} container alignItems="center">
          <Grid xs={6}>
            <List dense={true}>
              <Tooltip title="The size of the audience" placement="top">
                {/*<ListItem sx={{'&:hover': {backgroundColor: color.light, borderRadius: '6px'}}}>*/}
                <ListItem>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText primary={abbreviateNumber(audienceSize)} />
                </ListItem>
              </Tooltip>
            </List>
            <List>
              <Tooltip
                title="The highest index age band of people in the audience"
                placement="bottom"
              >
                <ListItem>
                  <ListItemIcon>
                    <Cake />
                  </ListItemIcon>
                  <ListItemText
                    primary={topAge.name}
                    secondary={formatPercentage(topAge.penetration)}
                  />
                </ListItem>
              </Tooltip>
            </List>
          </Grid>
          <Grid xs={6}>
            <List dense={true}>
              <Tooltip
                title="The expected market size of the audience"
                placement="top"
              >
                <ListItem>
                  <ListItemIcon>
                    <PeopleAlt />
                  </ListItemIcon>
                  <ListItemText primary={abbreviateNumber(modelledSize)} />
                </ListItem>
              </Tooltip>
            </List>
            <List>
              <Tooltip
                title="The most prominent gender of people in the audience"
                placement="bottom"
              >
                <ListItem>
                  <ListItemIcon>
                    {topGender.name == "male" ? <Male /> : <Female />}
                  </ListItemIcon>
                  <ListItemText
                    primary={titleCase(topGender.name)}
                    secondary={formatPercentage(topGender.penetration)}
                  />
                </ListItem>
              </Tooltip>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </InsightCard>
  );
};
