import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {FC} from "react";

interface IDeleteAudienceDialogProps {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
	audienceName: string;
}

export const DeleteAudienceDialog: FC<IDeleteAudienceDialogProps> = ({open, onClose, onConfirm, audienceName}) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
		>
			<DialogTitle>
				Are you sure you want to delete this audience?
			</DialogTitle>
			<DialogContent>
				<Typography>Please confirm you wish to delete <b>{audienceName}</b> from your saved audiences.</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>
					Cancel
				</Button>
				<Button variant="contained" onClick={onConfirm}>
					Delete Audience
				</Button>
			</DialogActions>
		</Dialog>
	)
}
