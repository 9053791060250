import { starsFilterOperators } from "@/constants";
import { QueryOperator } from "@/enums";
import { IStar } from "@/interfaces";
import { CustomChip } from "@/styles/components";
import { Box, Typography } from "@mui/material";
import React, { FC, useMemo } from "react";

interface QueryTextProps {
  operator: string;
  limiter: number;
  selectedStars: IStar[] | null;
  removeStar?: boolean;
  handleRemoveStar?: (id: IStar) => void;
}

export const QueryText: FC<QueryTextProps> = ({
  operator,
  limiter,
  selectedStars,
  removeStar = true,
  handleRemoveStar,
}) => {
  const displayLimiter =
    (operator === QueryOperator.MORE_THAN ||
      operator === QueryOperator.LESS_THAN) &&
    limiter > 0;

  const queryText = useMemo(() => {
    const uniqueStars = [
      ...new Map(selectedStars?.map((v) => [v.id, v])).values(),
    ];

    if (["AND", "OR"].includes(operator)) {
      return uniqueStars.map((star, index) => ({
        chip: (
          <CustomChip
            label={star.name}
            key={star.name}
            size="small"
            color="primary"
            onDelete={
              removeStar
                ? () => (handleRemoveStar ? handleRemoveStar(star) : undefined)
                : undefined
            }
            sx={{ m: 1 }}
          />
        ),
        operator:
          index !== (uniqueStars.length || 0) - 1 ? operator : undefined,
      }));
    }
    if (starsFilterOperators.includes(operator)) {
      return [
        {
          text: (
            <>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="white"
                ml={0.5}
              >
                {operator}
                {displayLimiter && ` ${limiter}`}
              </Typography>
              <Typography variant="body1" ml={0.5}>
                of
              </Typography>
            </>
          ),
          chips: uniqueStars.map((star) => (
            <CustomChip
              label={star.name}
              key={star.name}
              size="small"
              color="primary"
              onDelete={
                removeStar
                  ? () =>
                      handleRemoveStar ? handleRemoveStar(star) : undefined
                  : undefined
              }
              sx={{ m: 1 }}
            />
          )),
        },
      ];
    }
    return [];
  }, [operator, removeStar, handleRemoveStar]);

  return (
    <Box display="flex" flexWrap="wrap" flexGrow={1} alignItems="center">
      <Typography variant="body1">Followers of</Typography>
      {queryText.map((segment) => {
        if ("chip" in segment) {
          return (
            <React.Fragment key={segment.chip?.props.label}>
              {segment.chip}
              {segment.operator && (
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="white"
                  ml={0.5}
                >
                  {segment.operator}
                </Typography>
              )}
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={segment.text.props.children}>
              {segment.text}
              {segment.chips?.map((chip) => chip)}
            </React.Fragment>
          );
        }
      })}
    </Box>
  );
};
