import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { useSnackbar } from "@/context/snackbar-context";

export const GlobalSnackbar = () => {
  const { snackbar, closeSnackbar } = useSnackbar();

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={closeSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Alert
        onClose={closeSnackbar}
        severity={snackbar.type}
        sx={{ width: "100%" }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};
