import { Skeleton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";

export const InsightPageSkeleton = () => {
  return (
    <Grid container spacing={2}>
      <Grid xs={2}>
        <Skeleton variant="rounded" animation="wave" width={180} height={30} />
      </Grid>
      <Grid xs={10}>
        <Skeleton variant="rounded" animation="wave" width={130} height={40} />
      </Grid>
      <Grid xs={12}>
        <Skeleton variant="text" animation="wave" width={300} height={40} />
      </Grid>
      <Grid xs={12}>
        <Skeleton variant="rounded" animation="wave" width={1110} height={50} />
      </Grid>
      <Grid xs={12} display={"flex"} flexDirection={"row"} gap={2}>
        <Skeleton variant="rounded" animation="wave" width={360} height={240} />
        <Skeleton variant="rounded" animation="wave" width={360} height={240} />
        <Skeleton variant="rounded" animation="wave" width={360} height={240} />
      </Grid>
      <Grid xs={12} display={"flex"} flexDirection={"row"} gap={2}>
        <Skeleton variant="rounded" animation="wave" width={360} height={240} />
        <Skeleton variant="rounded" animation="wave" width={360} height={240} />
        <Skeleton variant="rounded" animation="wave" width={360} height={240} />
      </Grid>
    </Grid>
  );
};
