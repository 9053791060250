import {useAuth0} from "@auth0/auth0-react";
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';


export const UserProfile = () => {

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { user, logout } = useAuth0();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography variant='body2' sx={{ mr: 1 }}>{user ? user.name : ''}</Typography>
        <IconButton onClick={handleOpenUserMenu}>
          <Avatar sx={{ bgcolor: 'white', width: 32, height: 32}}>
            <PersonIcon fontSize='small'/>
          </Avatar>
        </IconButton>
      </Box>
      <Menu
        id="user-profile-menu"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        sx={{ mt: '32px' }}
      >
        <MenuItem key='logout' onClick={handleLogout}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
