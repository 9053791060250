import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import {Box, Button, Fade, IconButton, Slider, Typography,} from "@mui/material";
import React, {useState} from "react";

interface StarFollowersSliderProps {
  onFollowersChange: (bounds: number[]) => void;
}

const options = [0, 5000, 10000, 50000, 100000, 500000, 1000000, 5000000, 10000000];
const labels = ["0", "5k", "10k", "50k", "100k", "500k", "1m", "5m", "10m"];
const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 2,
    label: "10k",
  },
  {
    value: 4,
    label: "100k",
  },
  {
    value: 6,
    label: "1m",
  },
  {
    value: 8,
    label: "10m",
  },
];

export const StarFollowersSlider: React.FC<StarFollowersSliderProps> = ({
  onFollowersChange,
}) => {
  const [value, setValue] = useState<number[]>([0, 8]);
  const [openSlider, setOpenSlider] = useState<boolean>(false);

  const getValue = (n: number) => {
    return options[n];
  };

  const getLabel = (n: number) => {
    return labels[options.indexOf(n)];
  };

  const handleChange = (
    _event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      const bounds = [Math.min(newValue[0], value[1] - 1), value[1]];
      setValue(bounds);
      onFollowersChange([getValue(bounds[0]), getValue(bounds[1])]);
    } else {
      const bounds = [value[0], Math.max(newValue[1], value[0] + 1)];
      setValue(bounds);
      onFollowersChange([getValue(bounds[0]), getValue(bounds[1])]);
    }
  };

  const handleClear = () => {
    setValue([0, 8]);
    onFollowersChange([getValue(0), getValue(8)]);
  };

  return (
    <Box
      p={0.9}
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.09)",
        borderRadius: "4px",
        cursor: "pointer",
        position: "relative",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        onClick={() => setOpenSlider(!openSlider)}
      >
        <Box>
          <Typography
            variant="body2"
            color="textSecondary"
            ml="4px"
            sx={{ fontSize: "12px" }}
          >
            Followers
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            ml="4px"
            sx={{ fontSize: "12px" }}
          >
            {getLabel(getValue(value[0]))} - {getLabel(getValue(value[1]))}
          </Typography>
        </Box>
        <Box>
          {openSlider ? (
            <IconButton size="small" onClick={() => setOpenSlider(false)}>
              <ArrowDropUp />
            </IconButton>
          ) : (
            <IconButton size="small" onClick={() => setOpenSlider(true)}>
              <ArrowDropDown />
            </IconButton>
          )}
        </Box>
      </Box>
      {openSlider && (
        <Fade in={openSlider}>
          <Box
            px={4}
            py={2}
            sx={{
              position: "absolute",
              zIndex: 1,
              backgroundColor: "#444D5A",
              borderRadius: "4px",
              width: "100%",
              left: 0,
            }}
          >
            <Slider
              value={value}
              min={0}
              step={1}
              max={8}
              scale={getValue}
              valueLabelFormat={getLabel}
              onChange={handleChange}
              marks={marks}
              size="small"
              valueLabelDisplay="auto"
              disableSwap
            />
            <Button
              variant="outlined"
              size="small"
              onClick={handleClear}
              sx={{ mt: 2 }}
            >
              Clear
            </Button>
          </Box>
        </Fade>
      )}
    </Box>
  );
};
