import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


type DefaultImageProps = {
  size: number;
  name: string;
};

export const DefaultImage: React.FC<DefaultImageProps> = ({size, name}) => {

  return (
    <Box
      p={"4px"}
      width={size}
      height={size}
      sx={{overflow: "hidden", textOverflow: "ellipsis", textAlign: "center"}}
    >
      <Typography variant="caption">{name}</Typography>
    </Box>
  );
}
