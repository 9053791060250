import {Box, Typography} from "@mui/material";

import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from "chart.js";
import React from "react";
import {Bar} from "react-chartjs-2";

ChartJS.register(
  BarElement,
  Legend,
  Title,
  Tooltip,
  LinearScale,
  CategoryScale
);

function formatPercentage(value: number) {
  return `${(value * 100).toFixed(1)}%`
}


interface BarChartProps {
  title: string;
  labels: string[],
  values: number[],
  baseline?: number[]
}

export const BarChart: React.FC<BarChartProps> = ({title, labels, values, baseline}) => {

  const minVal = Number((Math.min.apply(Math, values) - 0.1).toFixed(1));
  const maxVal = Number(Math.ceil(Math.max.apply(Math, values) * 10)/10);

  return (
    <Box>
      <Typography variant="h6" mb={2}>{title}</Typography>
      <Box height={250}>
        <Bar
          options={{
            animation: false,
            responsive: true,
            maintainAspectRatio: false,
            datasets: {
              bar: {
                maxBarThickness: 30,
                barPercentage: 1,
                categoryPercentage: 0.7
              }
            },
            scales: {
              y: {
                min: minVal < 0 ? 0 : minVal,
                max: (maxVal * 100) % 2  === 0 ? maxVal : maxVal + 0.1,
                ticks: {
                  color: "white",
                  font: {
                    size: 10
                  },
                  maxTicksLimit: 6,
                  padding: 5,
                  callback: function(value,) { return value}
                },
                border: {
                  color: "white",
                },
                grid: {
                  color: "white",
                  drawOnChartArea: false,
                  tickLength: 4
                },
              },
              x: {
                ticks: {
                  color: "white",
                  font: {
                    size: 10
                  }
                },
                border: {
                  color: "white",
                },
                grid: {
                  display: false,
                },
              },
            },
            plugins: {
              legend: {
                display: true,
                position: "top",
                labels: {
                  boxWidth: 20,
                  boxHeight: 5,
                  useBorderRadius: true,
                  borderRadius: 2,
                  color: "white",
                  font: {
                    size: 10
                  }
                },
              },
              tooltip: {
                backgroundColor: "#2C3644",
                callbacks: {
                  label: function(context) {
                    return ' ' + formatPercentage(context.parsed.y);
                  }
                }
              }
            },
          }}
          data={{
            labels: labels,
            datasets: [
              {
                label: "Audience",
                data: values,
                backgroundColor: "#00B0CC",
                borderRadius: 4,
              },
              {
                label: "Baseline",
                data: baseline,
                backgroundColor: "#B1BAC6",
                borderRadius: 4
              },
            ],
          }}
        />
      </Box>
    </Box>
  );
};
