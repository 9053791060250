import {abbreviateNumber} from "@/helpers";
import {Stack, Typography} from "@mui/material";
import React from "react";

interface MetricProps {
	label: string,
	value: number,
}

export const Metric: React.FC<MetricProps> = (
	{
		label, value
	}) => {

	return (
		<Stack>
			<Typography variant="caption">
				{label}
			</Typography>
			<Typography variant="h5" color="primary">
				{abbreviateNumber(value)}
			</Typography>
		</Stack>
	);
}
