import {fetchData} from "@/api/utils";
import {ApiResponse} from "@/interfaces";
import {AxiosRequestConfig} from "axios";

export const searchStars = async (country: number, interests: number[], searchTerm: string): Promise<ApiResponse> => {
	const url = new URL(`${process.env.REACT_APP_API_URL}/search/stars`);
	const params = new URLSearchParams();
	params.append('country', country.toString());
	params.append('interests', interests.join(','));
	params.append('search_term', searchTerm);
	url.search = params.toString();

	const config: AxiosRequestConfig = {
		method: "GET",
		url: url.toString(),
	};

	return fetchData(config);
}
