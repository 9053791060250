import {fetchData} from "@/api/utils";
import {AxiosRequestConfig} from "axios";

export const getIndustries = async () => {
	const config: AxiosRequestConfig = {
		method: "GET",
		url: `${process.env.REACT_APP_API_URL}/list/industries`,
	};

	return fetchData(config);
}

