import {
  EditQueryGroupWarningDialog,
  QueryGroupSummarySkeleton,
  QuerySummary,
  QueryText,
} from "@/components";
import { OutlineBox } from "@/components/atoms";
import { QUERY_GROUP_STEP_HEIGHT } from "@/constants";
import { useIndustryList } from "@/hooks/useIndustryList";
import { useInterestList } from "@/hooks/useInterestList";
import { useStarTypesByInterestList } from "@/hooks/useStarTypesByInterestList";
import { IQueryGroup } from "@/interfaces";

import { Edit } from "@mui/icons-material";
import { Box, Chip, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { FC, useEffect, useMemo, useState } from "react";

interface QueryGroupSummaryProps {
  queryGroup: IQueryGroup;
  country: number;
  onSummaryChange: (summary: string) => void;
  onEdit: (step: number) => void;
}

export const QueryGroupSummary: FC<QueryGroupSummaryProps> = ({
  queryGroup,
  country,
  onSummaryChange,
  onEdit,
}) => {
  const { interests, query, operator, stars, query_mode, industry } =
    queryGroup;
  const [step, setStep] = useState(0);
  const [openChangesDialog, setOpenChangesDialog] = useState(false);

  const { interestList, loading: interestListLoading } = useInterestList();
  const interestsList =
    interestList?.filter((interest) => interests?.includes(interest.id)) || [];

  const { industryList, loading: industryListLoading } = useIndustryList();
  const industryName = industryList?.find((i) => i.id === industry)?.name || "";

  const { starTypesList, loading: starTypesListLoading } =
    useStarTypesByInterestList(country, interests || []);

  // remove duplicates
  const uniqueSelectedStars = stars?.filter(
    (star, index, self) => index === self.findIndex((s) => s.id === star.id)
  );

  const summaryContent = useMemo(() => {
    if (query_mode === "interest") {
      const selectedStarTypes = starTypesList.filter((type) =>
        query?.some((queryItem) => queryItem.type_filter?.includes(type.id))
      );

      // If `interests` exist, map over them and return a string, else return an empty string.
      return (
        interests
          ?.map((interest) => {
            const selectedInterest = interestList.find(
              (c) => c.id === interest
            );
            const queryItem = query?.find(
              (queryItem) => queryItem.interest_id === interest
            );

            const queryStarType = selectedStarTypes.filter((type) =>
              queryItem?.type_filter?.includes(type.id)
            );

            // return a string consisting of operator, limiter, interestName, and queryStarType name.
            return [
              `${queryItem?.operator?.toLowerCase()}`,
              queryItem?.operator?.toLowerCase() !== "any"
                ? queryItem?.limiter?.toString()
                : "",
              selectedInterest?.name,
              queryStarType?.map((type) => type.name).join(" or ") ||
                (queryItem?.limiter?.toString().includes("1")
                  ? "account"
                  : "accounts"),
            ]
              .filter(Boolean)
              .join(" ");
          })
          .join(`, ${operator?.toLowerCase()} `) || ""
      );
    } else {
      // If the `queryMode` is not "interest", select stars from `starsList` that are included in `stars` and join their names.
      const selectedStars = uniqueSelectedStars?.map((v) => v.name).join(", ");

      // If it's 'ANY' or 'ALL', add the operator to the string, otherwise, return limiter and the joined `selectedStars`.
      return queryGroup?.operator === "ANY" || queryGroup?.operator === "ALL"
        ? `${queryGroup.operator?.toLowerCase()} of ${selectedStars}`
        : `${queryGroup.operator?.toLowerCase()} ${
            queryGroup.limiter
          } ${selectedStars} `;
    }
  }, [starTypesList, queryGroup?.operator, uniqueSelectedStars]);

  useEffect(() => {
    onSummaryChange(`Followers of ${summaryContent}`);
  }, [summaryContent, onSummaryChange]);

  if (interestListLoading || industryListLoading || starTypesListLoading)
    return <QueryGroupSummarySkeleton />;

  const handleOnEdit = (step: number) => {
    setOpenChangesDialog(true);
    setStep(step);
  };

  const onChangesConfirm = () => {
    setOpenChangesDialog(false);
    onEdit(step);
  };

  return (
    <Box sx={{ height: QUERY_GROUP_STEP_HEIGHT }}>
      <Grid xs={12}>
        <Box display="flex" alignItems="center" my={1}>
          <OutlineBox borderRadius={1} width="100%" p={1}>
            <Typography variant="body2" color="primary" fontWeight="bold">
              Industry
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Typography variant="body1">{industryName}</Typography>
              </Box>
              <IconButton onClick={() => handleOnEdit(0)}>
                <Edit />
              </IconButton>
            </Box>
          </OutlineBox>
        </Box>
        <Box display="flex" alignItems="center" my={1}>
          <OutlineBox borderRadius={1} width="100%" p={1}>
            <Typography variant="body2" color="primary" fontWeight="bold">
              Interests
            </Typography>
            <Box display="flex" justifyContent="space-between" pt={1}>
              <Box>
                {interests?.map((interest) => (
                  <Chip
                    key={interest}
                    label={
                      interestList?.find((c) => c.id === interest)?.name || ""
                    }
                    sx={{ mr: 1 }}
                    size="small"
                    color="primary"
                  />
                ))}
              </Box>
              <IconButton onClick={() => handleOnEdit(1)}>
                <Edit />
              </IconButton>
            </Box>
          </OutlineBox>
        </Box>
        <Box display="flex" alignItems="center" my={1}>
          <OutlineBox borderRadius={1} width="100%" p={1}>
            <Typography variant="body2" color="primary" fontWeight="bold">
              Query
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex">
                {query_mode?.toLowerCase() === "interest" ? (
                  <QuerySummary
                    paper={false}
                    interests={interestsList}
                    country={country}
                    query={query || []}
                    operator={operator || ""}
                  />
                ) : (
                  <QueryText
                    operator={operator || ""}
                    limiter={queryGroup.limiter}
                    selectedStars={stars}
                    removeStar={false}
                  />
                )}
              </Box>
              <IconButton onClick={() => handleOnEdit(2)}>
                <Edit />
              </IconButton>
            </Box>
          </OutlineBox>
        </Box>
      </Grid>

      {openChangesDialog && (
        <EditQueryGroupWarningDialog
          open={openChangesDialog}
          onClose={() => setOpenChangesDialog(false)}
          onConfirm={onChangesConfirm}
        />
      )}
    </Box>
  );
};
