import {Grid, Paper, Skeleton} from "@mui/material";
import React from "react";

export const HomePageSkeleton = () => {
	return (
		<Grid item xs={12} md={10}>
			<Grid item xs={6} mb={4}>
				<Grid container direction="row" alignItems="center" spacing={2}>
					<Grid item xs={7}>
						<Skeleton variant="text" width={445} height={80}/>
					</Grid>
					<Grid item xs={5}>
						<Skeleton variant="text" width={313} height={80}/>
					</Grid>
				</Grid>
			</Grid>
			<Grid container direction="row">
				<Paper elevation={3} sx={{padding: 2, width: '100%'}}>
					<Grid item xs={12} my={2}>
						<Skeleton variant="text" width={'100%'} height={90}/>
					</Grid>
					<Grid item xs={12} mt={2}>
						<Skeleton variant="text" width={210} height={60}/>
					</Grid>
					<Grid item xs={12} my={2}>
						<Skeleton variant="text" width={'100%'} height={90}/>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	)
}
