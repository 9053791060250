import { fetchData } from "@/api/utils";
import { ApiResponse } from "@/interfaces";
import { AxiosRequestConfig } from "axios";

export const getStars = async (
  country: number,
  interests?: number[],
  types?: number[]
): Promise<ApiResponse> => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/list/stars`);
  const params = new URLSearchParams();
  params.append("country", country.toString());

  if (interests?.length) {
    params.append("interests", interests.join(","));
  }

  if (types?.length) {
    params.append("types", types.join(","));
  }

  url.search = params.toString();

  const config: AxiosRequestConfig = {
    method: "GET",
    url: url.toString(),
  };

  return fetchData(config);
};
