import {interestFilterOperators} from "@/constants";
import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography,} from "@mui/material";
import React, {FC} from "react";

interface FilterProps {
  operator: string;
  onOperatorChange: (operator: string) => void;
}

export const Filters: FC<FilterProps> = ({ onOperatorChange, operator }) => {
  const handleOperatorChange = (event: SelectChangeEvent) =>
    onOperatorChange?.(event.target.value);

  return (
    <Box>
      <Typography variant="body2" fontWeight="bold">
        Options
      </Typography>
      <Box mt={1}>
        <FormControl variant="filled" fullWidth sx={{ borderRadius: 1 }}>
          <InputLabel>Operator</InputLabel>
          <Select
            variant="filled"
            value={operator || ""}
            onChange={handleOperatorChange}
          >
            {interestFilterOperators.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
