import {starsFilterOperators} from "@/constants";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React, {FC} from "react";

interface StarOperatorDropdownProps {
    operator: string;
  onOperatorChange: (operator: string) => void;
}
export const StarOperatorDropdown: FC<StarOperatorDropdownProps> = ({
  onOperatorChange,
  operator,
}) => {
  return (
    <FormControl variant="filled" fullWidth sx={{ borderRadius: 1 }}>
      <InputLabel>Operator</InputLabel>
      <Select
        size="small"
        variant="filled"
        value={operator}
        onChange={(event) => onOperatorChange(event.target.value)}
      >
        {starsFilterOperators.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
