import {OutlineBox} from "@/components";
import {Box, Paper, Skeleton, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, {FC} from "react";

export const InterestSelectionSkeleton: FC = () => {
  return (
    <Grid container xs={12} spacing={2} sx={{ height: "100%" }}>
      <Grid xs={12}>
        <Skeleton variant="rounded" height="56px" width="100%"/>
      </Grid>
      <Grid xs={12}>
        <Skeleton variant="rounded" height="56px" width="100%" />
      </Grid>
      <Grid xs={12} height='calc(100% - 144px)'>
        {Array.from({ length: 1 }).map((_, index) => (
          <Paper
            key={index}
            elevation={2}
            sx={{
              p: 2,
              mb: 2,
              width: "100%",
            }}
          >
            <Typography variant="body1" sx={{ mb: 1 }}>
              <Skeleton width={100} />
            </Typography>
            <Grid container spacing={2}>
              {Array.from({ length: 4 }).map((_, index) => (
                <Grid xs={6} key={index}>
                  <OutlineBox
                    borderColor="divider"
                    borderRadius={1}
                    p={2}
                    mt={2}
                  >
                    <Box display="flex" mb={1}>
                      <Grid container xs={12}>
                        <Grid xs={12} mb={2}>
                          <Typography variant="body1">
                            <Skeleton width={100} />
                          </Typography>
                        </Grid>

                        {Array.from({ length: 6 }).map((_, index) => (
                          <Skeleton
                            key={index}
                            variant="rounded"
                            width={100}
                            height={24}
                            sx={{ mr: 2, mb: 2 }}
                          />
                        ))}
                      </Grid>
                    </Box>
                  </OutlineBox>
                </Grid>
              ))}
            </Grid>
          </Paper>
        ))}
      </Grid>
    </Grid>
  );
};
