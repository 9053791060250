import { GlobalSnackbar } from "@/components";
import { ProtectedRoute } from "@/components/routes";
import { AudienceProvider } from "@/context/audience-context";
import { SnackbarProvider } from "@/context/snackbar-context";
import { IAudience } from "@/interfaces";
import { AudiencePage } from "@/pages/audience-page";
import { InsightPage } from "@/pages/insight-page";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { UnauthorizedPage } from "./pages/unauthorized-page";

const defaultState: IAudience[] = [];

function App() {
  return (
    <SnackbarProvider>
      <AudienceProvider initialState={defaultState}>
        <Router>
          <Switch>
            <ProtectedRoute path="/" exact component={HomePage} />
            <ProtectedRoute
              exact
              path="/audience/new"
              component={AudiencePage}
            />
            <ProtectedRoute
              exact
              path="/audience/:id"
              component={AudiencePage}
            />
            <ProtectedRoute
              exact
              path="/audience/:id/insight"
              component={InsightPage}
            />
            <Route path="/callback" component={CallbackPage} />
            <Route path="*" component={NotFoundPage} />
            {/* If the user is rejected by log-in, redirect them to the unauthorized page */}
            <Route path="/unauthorized" component={UnauthorizedPage} />

            {/* Redirect /login to AppWrapper to delegate auth decisions */}
            <Redirect from="/login" to="/" />
          </Switch>
        </Router>
        <GlobalSnackbar />
      </AudienceProvider>
    </SnackbarProvider>
  );
}

export default App;
