import {
  Avatar,
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Face, Person } from "@mui/icons-material";
import React, { memo, useCallback, useEffect, useState } from "react";

import { CustomChip } from "@/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { IStar } from "@/interfaces";
import { OutlineBox } from "@/components";
import { TransitionGroup } from "react-transition-group";
import { formatFollowersCount } from "@/utils";

interface StarTypeGroupProps {
  name: string;
  stars: IStar[] | null;
  selectedStars: IStar[] | null;
  onAllStarChange?: (stars: IStar[]) => void;
  onStarChange: (star: IStar) => void;
  onStarRemove?: (star: IStar) => void;
}

const SHOW_STEP = 10;

const isStarSelected = (selectedStars: IStar[] | null, star: IStar) => {
  return selectedStars?.some((sub) => sub.id === star.id);
};

export const StarTypeGroup: React.FC<StarTypeGroupProps> = memo(
  ({
    name,
    stars,
    selectedStars,
    onAllStarChange,
    onStarChange,
    onStarRemove,
  }) => {
    const [currentStars, setCurrentStars] = useState<IStar[]>([]);
    const [numShown, setNumShown] = useState<number>(SHOW_STEP);
    const [allStarsSelected, setAllStarsSelected] = useState<boolean>(false);
    useEffect(() => {
      if (stars) {
        setCurrentStars(stars.slice(0, numShown) || []);
      }
    }, [stars, numShown]);

    useEffect(() => {
      const allStarsSelected = stars?.every((star) =>
        selectedStars?.includes(star)
      );
      if (allStarsSelected !== undefined) setAllStarsSelected(allStarsSelected);
    }, [stars, selectedStars]);

    const handleMore = () => {
      if (stars)
        setNumShown(Math.min(currentStars.length + SHOW_STEP, stars.length));
    };

    const handleLess = () => {
      setNumShown(Math.max(currentStars.length - SHOW_STEP, SHOW_STEP));
    };

    const handleStarSelection = useCallback(
      (star: IStar) => {
        onStarChange(star);
      },
      [onStarChange]
    );

    const handleSelectAllStars = () =>
      onAllStarChange && stars && onAllStarChange(stars);

    return (
      <Grid xs={6}>
        <OutlineBox
          borderColor="divider"
          borderRadius={1}
          p={2}
          mt={2}
          sx={{
            height: 150,
            overflowY: "auto",
          }}
        >
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {name}
            </Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={handleSelectAllStars}
            >
              {allStarsSelected
                ? "Deselect All"
                : `Select All (${stars?.length || 0})`}
            </Button>
          </Box>
          <TransitionGroup style={{ display: "flex", flexWrap: "wrap" }}>
            {currentStars.map((star, index) => {
              const isSelected = isStarSelected(selectedStars, star);
              const ChipComponent = isSelected ? CustomChip : Chip;
              const onDelete =
                isSelected && onStarRemove
                  ? () => onStarRemove(star)
                  : undefined;

              return (
                <Collapse key={`${name}-${index}`}>
                  <ChipComponent
                    avatar={
                      <Avatar
                        src={star.image_url}
                        alt="star"
                        sx={{ width: 15, height: 15 }}
                      >
                        <Face />
                      </Avatar>
                    }
                    label={
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <span>{star.name}</span>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ height: "18px" }}
                          />
                          <Person
                            sx={{
                              ml: 0.5,
                              mr: 0.2,
                              width: "15px",
                              height: "15px",
                            }}
                          />
                          <span>{formatFollowersCount(star.followers)}</span>
                        </Stack>
                      </Stack>
                    }
                    size="small"
                    color={isSelected ? "primary" : "default"}
                    onClick={() => handleStarSelection(star)}
                    onDelete={onDelete}
                    sx={{ mt: 1, mr: 1, mb: 1 }}
                  />
                </Collapse>
              );
            })}
          </TransitionGroup>
          <Box justifyContent={"center"} display={"flex"} mt={2}>
            {currentStars.length > SHOW_STEP && (
              <Box mr={1}>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={handleLess}
                >
                  Show L{" "}
                </Button>
              </Box>
            )}
            {currentStars.length < (stars?.length ?? 0) && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleMore}
              >
                Show More ({(stars?.length ?? 0) - currentStars.length})
              </Button>
            )}
          </Box>
        </OutlineBox>
      </Grid>
    );
  }
);
