import {styled, SxProps, Theme} from "@mui/material";

export const StyledGridOverlay = styled('div')(({theme}) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100%',
	'& .ant-empty-img-1': {
		fill: theme.palette.primary.dark,
	},
	'& .ant-empty-img-2': {
		fill: theme.palette.secondary.light,
	},
	'& .ant-empty-img-3': {
		fill: theme.palette.primary.dark,
	},
	'& .ant-empty-img-4': {
		fill: theme.palette.secondary.light
	},
	'& .ant-empty-img-5': {
		fill: theme.palette.secondary.light,
	},
}));

export const selectedCardStyle: SxProps<Theme> = {
	border: 1,
	borderColor: 'primary.main',
	color: 'primary.main'
}
