import {InterestSearch, InterestSelectionSkeleton} from "@/components";
import {useInterestByIndustryList} from "@/hooks/useInterestByIndustryList";
import {IList} from "@/interfaces";
import {CustomChip} from "@/styles/components";

import {Close} from "@mui/icons-material";
import {Box, Paper, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, {useEffect, useState} from "react";

interface InterestSelectionProps {
  industry: number | null;
  interests: number[];
  onClearInterests: () => void;
  onInterestChange: (interest: number) => void;
  onInterestDelete: (id: number) => void;
}

export const InterestSelection: React.FC<InterestSelectionProps> = ({
  onClearInterests,
  onInterestChange,
  onInterestDelete,
  industry,
  interests,
}) => {
  const { interestList, loading } = useInterestByIndustryList(industry || 0);
  const [searchText, setSearchText] = useState<string>("");
  const [filteredInterests, setFilteredInterests] = useState<IList[]>([]);

  const groupList = loading
    ? []
    : [...new Set(interestList.map((interest) => interest.group))];

  useEffect(() => {
    if (searchText !== "")
      setFilteredInterests(
        interestList.filter(
          (option) =>
            option.name.toLowerCase().includes(searchText.toLowerCase()) ||
            option.group.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    else setFilteredInterests([]);
  }, [searchText, interestList]);

  const handleInterestChange = (interest: number | undefined) => {
    if (interest === undefined) {
      return;
    }
    onInterestChange(interest);
  };

  const handleInterestSearchChange = (text: string) => setSearchText(text);

  const handleInterestDelete = (interest: number) => onInterestDelete(interest);

  const handleClearInterests = () => onClearInterests();

  if (loading) return <InterestSelectionSkeleton />;

  const renderGroupList = () => {
    return groupList.map((group) => {
      const groupInterests = (
        searchText !== "" ? filteredInterests : interestList
      ).filter((interest) => interest.group === group);

      if (groupInterests.length > 0) {
        return (
          <Grid key={`interest-group-${group}`} xs={6}>
            <Box
              border={1}
              borderRadius={1}
              p={2}
              sx={{
                height: 135,
                overflowY: "auto",
              }}
            >
              <Typography variant="body1" color="primary" sx={{ mb: 1 }}>
                {group}
              </Typography>
              <Grid container spacing={1}>
                {groupInterests.map((item) => (
                  <CustomChip
                    key={`interest-${item.id}`}
                    label={item.name}
                    onClick={() =>
                      !interests.find((sub) => sub === item.id) &&
                      handleInterestChange(item.id)
                    }
                    size="small"
                    color={
                      interests.find((sub) => sub === item.id)
                        ? "primary"
                        : "default"
                    }
                    onDelete={
                      !!interests.find((sub) => sub === item.id)
                        ? () => handleInterestDelete(item.id)
                        : undefined
                    }
                    sx={{
                      mt: 1,
                      mr: 1,
                      mb: 1,
                    }}
                  />
                ))}
              </Grid>
            </Box>
          </Grid>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <Grid container xs={12} spacing={2} sx={{ height: "100%" }}>
      <Grid xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "64px",
          }}
        >
          <Box display="flex" flexWrap="wrap" flexGrow={1} alignItems="center">
            <Typography variant="body1" mr={2}>Selected interests: </Typography>
            {interests.map((subItem, index) => (
              <CustomChip
                label={interestList.find((item) => item.id === subItem)?.name}
                key={index}
                size="small"
                color="primary"
                onDelete={() => handleInterestDelete(subItem)}
                sx={{
                  mr: 1,
                  my: 0.5
                }}
              />
            ))}
          </Box>
          {interests.length > 0 && (
            <Close
              fontSize="small"
              sx={{ cursor: "pointer" }}
              onClick={handleClearInterests}
            />
          )}
        </Paper>
      </Grid>
      <Grid xs={12}>
        <Box>
          <InterestSearch onInterestSearchChange={handleInterestSearchChange} />
        </Box>
      </Grid>
      <Grid
        xs={12}
        container
        height={{ xs: "calc(100% - 156px)", md: "calc(100% - 156px)" }}
      >
        <Grid xs={12} sx={{ height: "100%" }}>
          <Paper elevation={2} sx={{ p: 2, overflowY: "auto", height: "100%" }}>
            {searchText !== "" && filteredInterests.length === 0 ? (
              <Typography>
                No Interests found that match the search text
              </Typography>
            ) : (
              <Grid container spacing={2}>
                {renderGroupList()}
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};
