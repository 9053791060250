import {Search} from "@mui/icons-material";
import {TextField} from "@mui/material";
import React from "react";

interface StarSearchProps {
  onStarSearchChange: (searchText: string) => void;
}

export const StarSearch: React.FC<StarSearchProps> = ({
  onStarSearchChange,
}) => {
  return (
    <TextField
      size="small"
      label="Search"
      variant="filled"
      fullWidth
      placeholder="Search for an account"
      onChange={(event) =>
        event.target.value
          ? onStarSearchChange(event.target.value)
          : onStarSearchChange("")
      }
      InputProps={{ endAdornment: <Search /> }}
    />
  );
};
