import {IAudience} from "@/interfaces";
import {
	ADD_QUERY_GROUP,
	BUILD_AUDIENCE,
	BUILD_SEGMENTS,
	CREATE_AUDIENCE,
	DELETE_AUDIENCE,
	DELETE_QUERY_GROUP,
	SET_AUDIENCE,
	UPDATE_AUDIENCE,
	UPDATE_AUDIENCE_NAME,
	UPDATE_AUDIENCE_OPERATOR,
	UPDATE_QUERY_GROUP
} from "./audience-actions";
import {Action} from "./types";

export const audienceReducer = (audiences: IAudience[], action: Action): IAudience[] => {
	switch (action.type) {

		case CREATE_AUDIENCE:
			return [...audiences, action.payload];

		case SET_AUDIENCE:
			return action.payload;

		case UPDATE_AUDIENCE:
			return audiences.map(item => {
				if (item.id === action.payload.id) {
					return action.payload;
				}
				return item;
			});

		case DELETE_AUDIENCE:
			const {audienceId, isNewAudience} = action.payload;
			const updatedAudiences = [...audiences];

			if (isNewAudience) {
				// delete query group
				updatedAudiences[updatedAudiences.length - 1].query_groups.pop();
				// delete audience
				updatedAudiences.pop();
				return updatedAudiences;
			}
			return updatedAudiences.filter(item => item.id !== audienceId);

		case UPDATE_AUDIENCE_NAME:
			return audiences.map(item => {
				if (item.id === action.payload.audienceId) {
					return {...item, name: action.payload.name};
				}
				return item;
			});

		case UPDATE_AUDIENCE_OPERATOR:
			return audiences.map(item => {
				if (item.id === action.payload.audienceId) {
					return {...item, operator: action.payload.operator};
				}
				return item;
			});

		case ADD_QUERY_GROUP: {
			const {audienceId, queryGroup, isNewAudience} = action.payload;
			const updatedAudiences = [...audiences];

			if (isNewAudience) {
				const newAudience = updatedAudiences[updatedAudiences.length - 1];
				newAudience.query_groups.push(queryGroup);
			} else {
				updatedAudiences.map(audience => {
					if (audience.id === audienceId) {
						audience.query_groups.push(queryGroup);
					}
					return audience;
				});
			}
			return updatedAudiences;
		}

		case UPDATE_QUERY_GROUP:
			return audiences.map(item => {
				if (item.id === action.payload.audienceId) {
					return {
						...item,
						query_groups: item.query_groups.map(value => {
							if (value.id === action.payload.queryGroup.id) {
								return action.payload.queryGroup;
							}
							return value;
						})
					}
				}
				return item;
			});

		case DELETE_QUERY_GROUP: {
			const {audienceId, queryGroupId, isNewAudience} = action.payload;
			const updatedAudiences = [...audiences];

			if (isNewAudience) {
				updatedAudiences[updatedAudiences.length - 1].query_groups.pop();
			} else {
				return updatedAudiences.map(audience => {
					if (audience.id === audienceId) {
						audience.query_groups = audience.query_groups.filter(queryGroup => queryGroup.id !== queryGroupId);
						return audience;
					}
					return audience;
				});
			}
			return updatedAudiences;
		}

		case BUILD_AUDIENCE: {
			const {audienceId, runId, runDate, requestId, modelledCount, socialCount} = action.payload;
			return audiences.map(item => {
				if (item.id === audienceId) {
					return {
						...item,
						run_id: runId,
						run_date: runDate,
						request_id: requestId,
						modelled_count: modelledCount,
						social_count: socialCount
					};
				}
				return item;
			});
		}

		case BUILD_SEGMENTS: {
			const {audienceId, segmentRunId, segmentRunDate, segmentCount} = action.payload;
			return audiences.map(item => {
				if (item.id === audienceId) {
					return {...item, segment_run_id: segmentRunId, segment_run_date: segmentRunDate, segment_count: segmentCount};
				}
				return item;
			});
		}

		default:
			return audiences;
	}
};
