import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { FC } from "react";

interface ChangeQueryModeWarningDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ChangeQueryModeWarningDialog: FC<
  ChangeQueryModeWarningDialogProps
> = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Changing Query Mode</DialogTitle>
      <DialogContent>
        <Typography>
          Warning: Changing the query mode will clear your current query.
        </Typography>
        <Typography mt={2}>Are you sure you wish to continue?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
