import {getIndustries} from "@/api/list";
import {Industry} from "@/interfaces";
import {useEffect, useState} from "react";

export const useIndustryList = () => {
	const [industryList, setIndustryList] = useState<Industry[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		setLoading(true);
		getIndustries()
			.then((response) => {
				setIndustryList(response.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	return {industryList, loading, error};
}
