import {StarTypeSearch} from "@/components";
import {interestQueryOperators} from "@/constants";
import {QueryOperator} from "@/enums";
import {IList, InterestQuery} from "@/interfaces";
import {FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField,} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, {ChangeEvent, useMemo} from "react";

interface QueryBlockProps {
  interest: IList;
  country: number;
  queryItem: InterestQuery;
  onQueryStarTypeChange: (type: number[]) => void;
  onQueryOperatorChange: (event: SelectChangeEvent) => void;
  onQueryLimiterChange: (event: ChangeEvent<HTMLInputElement>) => void;
  editMode?: boolean;
}

export const QueryBlock: React.FC<QueryBlockProps> = ({
  interest,
  country,
  queryItem,
  onQueryStarTypeChange,
  onQueryOperatorChange,
  onQueryLimiterChange,
}) => {
  const interests = useMemo(() => [interest.id], [interest.id]);
  return (
    <Grid xs={12} md={12}>
      <Paper sx={{ p: 1.5 }}>
        <Grid container spacing={2}>
          <Grid xs={3}>
            <TextField
              size="small"
              fullWidth
              name={interest.name}
              label="Interest"
              variant="filled"
              value={interest.name}
              InputLabelProps={{
                shrink: interest.name ? true : undefined,
                sx: { fontWeight: "bold" },
              }}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid xs={3}>
            <FormControl fullWidth variant="filled">
              <InputLabel>Operator</InputLabel>
              <Select
                size="small"
                variant="filled"
                value={queryItem.operator || ""}
                onChange={onQueryOperatorChange}
                sx={{ textTransform: "uppercase" }}
              >
                {interestQueryOperators.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={2}>
            <TextField
              size="small"
              fullWidth
              disabled={queryItem.operator === QueryOperator.ANY}
              label="Limiter"
              type="number"
              variant="filled"
              value={queryItem.limiter || 1}
              onChange={onQueryLimiterChange}
              InputLabelProps={{ shrink: !!queryItem.operator }}
            />
          </Grid>
          <Grid xs={4}>
            <StarTypeSearch
              interests={interests}
              country={country}
              types={queryItem.type_filter}
              onStarTypeChange={(type) =>
                onQueryStarTypeChange(type.map((item: IList) => item.id))
              }
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
