import {OutlineBox} from "@/components";
import {QUERY_GROUP_STEP_HEIGHT} from "@/constants";
import {Skeleton, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from 'react';

export const QueryGroupSummarySkeleton = () => {
	return (
		<Grid xs={12} sx={{height: QUERY_GROUP_STEP_HEIGHT}}>
			{Array.from(new Array(4)).map((_, index) => (
				<OutlineBox key={index} borderRadius={1} width="100%" p={1} my={2}>
					<Typography variant="body2" mb={2}>
						<Skeleton variant='text' width={50}/>
					</Typography>
					<Skeleton variant='rounded' width={250}/>
				</OutlineBox>
			))}
		</Grid>
	);
};
