import { SegmentAudienceCard, SegmentCard } from "@/components";
import { IAudienceSegment } from "@/interfaces";
import { createSegmentColor } from "@/utils";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { FC, useEffect, useState } from "react";

type ComparisonViewProps = {
  segments: Array<IAudienceSegment>;
};

const segmentCardsLimit = 3;

export const ComparisonView: FC<ComparisonViewProps> = ({ segments }) => {
  const colors = createSegmentColor(segments);

  const [availableSegments, setAvailableSegments] = useState<
    IAudienceSegment[]
  >([]);
  const [selectedCompareSegments, setSelectedCompareSegments] = useState<
    Array<IAudienceSegment>
  >(Array(segmentCardsLimit).fill({} as IAudienceSegment));

  useEffect(() => {
    // remove audience from segments
    const availableSegments = segments.filter(
      (segment) => segment.name !== "Audience"
    );

    setAvailableSegments(availableSegments);
  }, [segments]);

  const handleSegmentChange = (segmentId: number, segmentIndex: number) => {
    const segment = segments.find((segment) => segment.id === segmentId);
    if (segment) {
      setSelectedCompareSegments((prev) => {
        const newSegments = [...prev];
        newSegments[segmentIndex] = segment;
        return newSegments;
      });
    }
  };

  const handleSegmentRemove = (segmentId: number) => {
    // find index in selectedCompareSegments and set it to empty object
    const segmentIndex = selectedCompareSegments.findIndex(
      (segment) => segment.id === segmentId
    );

    setSelectedCompareSegments((prev) => {
      const newSegments = [...prev];
      newSegments[segmentIndex] = {} as IAudienceSegment;
      return newSegments;
    });
  };

  const renderSegmentCards = () => {
    return selectedCompareSegments.map((segment, index) => {
      if (Object.keys(segment).length !== 0) {
        return (
          <SegmentCard
            key={index}
            segmentIndex={index}
            segment={segment}
            segments={availableSegments}
            selectedSegments={selectedCompareSegments}
            color={colors[segment.id]}
            onSegmentChange={handleSegmentChange}
            onSegmentDelete={handleSegmentRemove}
          />
        );
      } else {
        return (
          <Card
            key={index}
            variant="outlined"
            sx={{
              backgroundColor: "transparent",
              minWidth: "280px",
              width: "350px",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                height: "100%",
              }}
            >
              <FormControl variant="filled" size="small" fullWidth={true}>
                <InputLabel id="view-select-label">Choose a segment</InputLabel>
                <Select
                  displayEmpty
                  onChange={(e) =>
                    handleSegmentChange(parseInt(e.target.value), index)
                  }
                  placeholder={"Select a segment"}
                  fullWidth={true}
                  value=""
                  renderValue={(selected) => {
                    return selected;
                  }}
                >
                  {availableSegments?.map((segment, index) => (
                    <MenuItem
                      key={index}
                      value={segment.id}
                      disabled={selectedCompareSegments.some(
                        (selectedCompareSegment) =>
                          selectedCompareSegment.id === segment.id
                      )}
                    >
                      {segment.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
          </Card>
        );
      }
    });
  };

  return (
    <Paper
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "688px",
        gap: 2,
      }}
    >
      <Typography variant="h6">Compare Segments</Typography>

      <Box
        sx={{
          display: "flex",
          gap: 2,
        }}
      >
        <SegmentAudienceCard segment={segments[0]} />
        {renderSegmentCards()}
      </Box>
    </Paper>
  );
};
