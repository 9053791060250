import React, { FC } from "react";

import {Avatar, ImageList, ImageListItem, Typography, Box} from "@mui/material";

import {DefaultImage, InsightCard, Tooltip} from "@/components";
import { IStarScore } from "@/interfaces";

interface InsightCardImageProps {
  title: string;
  stars: IStarScore[];
}

export const InsightCardImage: FC<InsightCardImageProps> = ({
  title,
  stars,
}) => {
  return (
    <InsightCard title={title}>
      <ImageList
        sx={{ width: 328, height: 138, px: "16px", m: 0 }}
        cols={4}
        gap={18}
        rowHeight={60}
      >
        {stars.slice(0, 8).map((star) => (
          <Tooltip
            key={star.id}
            placement="bottom"
            title={
              <React.Fragment>
                <Typography>{star.name}</Typography>
              </React.Fragment>
            }
          >
            <ImageListItem sx={{ height: 60, width: 60 }}>
              <Avatar
                src={star.image_url}
                alt={star.name}
                variant="square"
                sx={{ width: 60, height: 60, borderRadius: '6px', color: '#fff' }}
              >
                <DefaultImage size={60} name={star.name} />
              </Avatar>
            </ImageListItem>
          </Tooltip>
        ))}
      </ImageList>
    </InsightCard>
  );
};
