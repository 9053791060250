import {Box, Skeleton} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, {FC} from "react";

export const InterestQuerySectionSkeleton: FC = () => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Skeleton variant="rounded" height={50} />
        </Grid>
      </Grid>
    </Box>
  );
};
