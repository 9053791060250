import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from '@mui/material';
import { Close, ExpandMore } from '@mui/icons-material';
import { IList, IStar, Interest } from '@/interfaces';
import {
  QueryText,
  StarQuerySectionSkeleton,
  StarTypeGroup,
} from '@/components';
import React, { useEffect, useState } from 'react';

import { Filters } from './filters';
import Grid from '@mui/material/Unstable_Grid2';
import { searchStars } from '@/api/search';
import { useInterestList } from '@/hooks/useInterestList';
import { useStarsList } from '@/hooks/useStarsList';

interface StarsQuerySectionProps {
  interests: number[];
  country: number;
  selectedStars: IStar[] | null;
  operator: string;
  limiter: number;
  onStarChange: (star: IStar) => void;
  onLimiterChange: (limiter: number) => void;
  onAllStarChange: (stars: IStar[]) => void;
  onStarRemove: (id: IStar) => void;
  onOperatorChange: (operator: string) => void;
  onClearStars: () => void;
  onInterestChange: (interest: number) => void;
  onIndustryChange: (industry: number) => void;
}

export const StarsQuerySection: React.FC<StarsQuerySectionProps> = ({
  interests,
  country,
  selectedStars,
  operator,
  limiter,
  onStarChange,
  onAllStarChange,
  onOperatorChange,
  onStarRemove,
  onClearStars,
  onLimiterChange,
  onInterestChange,
  onIndustryChange,
}) => {
  const { starsList, loading } = useStarsList(country, interests);
  const { interestList } = useInterestList();

  const [searchText, setSearchText] = useState<string>('');
  const [followerBounds, setFollowerBounds] = useState<number[]>([0, 10000000]);
  const [filteredStars, setFilteredStars] = useState<IStar[]>([]);
  const [searchedStars, setSearchedStars] = useState<IStar[]>([]);
  const [typesList, setTypesList] = useState<string[]>([]);
  const [searchedInterestList, setSearchedInterestList] = useState<Interest[]>(
    []
  );

  useEffect(() => {
    setTypesList([...new Set(starsList.map((star) => star.type_name))]);
  }, [starsList]);

  useEffect(() => {
    if (searchText !== '' || followerBounds) {
      // Filter the stars based on the selected options
      const subset = starsList.filter(
        (option) =>
          option.name
            .toLowerCase()
            .includes(searchText !== '' ? searchText.toLowerCase() : '') &&
          option.followers > (followerBounds ? followerBounds[0] : 0) &&
          option.followers <
            (followerBounds ? followerBounds[1] : Number.MAX_VALUE) &&
          typesList.includes(option.type_name)
      );
      setFilteredStars(subset);

      // Search the database for a suitable alternative
      if (searchText.length > 2) {
        searchStars(country, interests, searchText).then((response) => {
          setSearchedStars(response.data);
        });
      } else {
        setSearchedStars([]);
      }
    } else {
      setFilteredStars([]);
      setSearchedStars([]);
    }
  }, [searchText, followerBounds, starsList, typesList]);

  useEffect(() => {
    setSearchedInterestList(
      searchedStars
        .map((star) => ({ id: star.interest_id, name: star.interest_name }))
        .filter(
          (interest, index, self) =>
            index === self.findIndex((i) => i.id === interest.id)
        )
    );
  }, [searchedStars]);

  const handleOperatorChange = (operator: string) => onOperatorChange(operator);

  const handleStarChange = (star: IStar) => onStarChange(star);

  const handleSearchedStarChange = (star: IStar) => {
    onStarChange(star);
    onInterestChange(star.interest_id);
    onIndustryChange(0);
  };

  const handleAllSearchedStarChange = (stars: IStar[]) => {
    onAllStarChange(stars);
    onInterestChange(stars[0].interest_id);
    onIndustryChange(0);
  };

  const handleStarTypeChange = (starTypes: IList[]) => {
    starTypes.length > 0
      ? setTypesList(starTypes.map((type) => type.name))
      : setTypesList([...new Set(starsList.map((star) => star.type_name))]);
  };

  const handleRemoveStar = (star: IStar) => onStarRemove(star);

  const handleStarSearchChange = (text: string) => setSearchText(text);

  const handleFollowersChange = (bounds: number[]) => setFollowerBounds(bounds);

  const handleClearStars = () => onClearStars();

  const handleLimiterChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onLimiterChange(Number(event.target.value));

  return (
    <Grid
      container
      xs={12}
      spacing={2}
      alignContent="flex-start"
      sx={{ height: 'calc(100% - 55px)' }}
    >
      <Grid xs={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: '72px',
          }}
        >
          <Box display="flex" flexWrap="wrap" flexGrow={1} alignItems="center">
            <QueryText
              operator={operator}
              limiter={limiter}
              selectedStars={selectedStars}
              removeStar
              handleRemoveStar={handleRemoveStar}
            />
          </Box>
          {selectedStars && selectedStars.length > 0 && (
            <Close
              fontSize="small"
              onClick={handleClearStars}
              sx={{ cursor: 'pointer' }}
            />
          )}
        </Paper>
      </Grid>

      <Grid xs={12}>
        <Filters
          interests={interests}
          country={country}
          operator={operator}
          limiter={limiter}
          onLimiterChange={handleLimiterChange}
          onStarSearchChange={handleStarSearchChange}
          onOperatorChange={handleOperatorChange}
          onStarTypeSearchChange={handleStarTypeChange}
          onStarFollowersChange={handleFollowersChange}
        />
      </Grid>

      {loading ? (
        <StarQuerySectionSkeleton />
      ) : (
        <Grid xs={12} sx={{ overflowY: 'auto', height: 'calc(100% - 153px)' }}>
          {interests.map((interest) => (
            <Accordion
              key={interest}
              defaultExpanded={true}
              elevation={2}
              sx={{ width: '100%' }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="body1">
                  {interestList.find((item) => item.id === interest)?.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ pt: 0 }}>
                <Grid container spacing={2}>
                  {(searchText !== '' || followerBounds) &&
                  filteredStars.filter((star) => star.interest_id === interest)
                    .length === 0 ? (
                    <Typography color="textSecondary">
                      No Accounts found that match the Search Text
                    </Typography>
                  ) : (
                    typesList.map((type) => {
                      if (searchText !== '' || followerBounds) {
                        const stars = filteredStars.filter(
                          (star) =>
                            star.interest_id === interest &&
                            star.type_name === type
                        );
                        if (stars.length > 0) {
                          return (
                            <React.Fragment key={`star-type-group-${type}`}>
                              <StarTypeGroup
                                name={type}
                                stars={stars}
                                selectedStars={selectedStars}
                                onAllStarChange={onAllStarChange}
                                onStarChange={handleStarChange}
                                onStarRemove={handleRemoveStar}
                              />
                            </React.Fragment>
                          );
                        } else {
                          return null;
                        }
                      } else {
                        const stars = starsList.filter(
                          (star) =>
                            star.interest_id === interest &&
                            star.type_name === type
                        );
                        if (stars.length > (starsList.length > 100 ? 1 : 0)) {
                          return (
                            <React.Fragment key={`star-type-group-${type}`}>
                              <StarTypeGroup
                                name={type}
                                stars={stars}
                                selectedStars={selectedStars}
                                onStarChange={handleStarChange}
                                onStarRemove={handleRemoveStar}
                              />
                            </React.Fragment>
                          );
                        } else {
                          return null;
                        }
                      }
                    })
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
          {searchedStars.length > 0 && (
            <Accordion
              defaultExpanded={true}
              elevation={2}
              sx={{ width: '100%' }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Did you mean?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ pt: 0 }}>
                <Grid container spacing={2}>
                  {searchedInterestList.map((interest) => {
                    const stars = searchedStars.filter(
                      (star) => star.interest_id === interest.id
                    );
                    return (
                      <React.Fragment
                        key={`star-interest-group-${interest.id}`}
                      >
                        <StarTypeGroup
                          name={interest.name || ''}
                          stars={stars}
                          selectedStars={selectedStars}
                          onAllStarChange={handleAllSearchedStarChange}
                          onStarChange={handleSearchedStarChange}
                          onStarRemove={handleRemoveStar}
                        />
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        </Grid>
      )}
      {/*</Grid>*/}
    </Grid>
  );
};
