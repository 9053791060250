import {DialogActions, DialogContent, DialogTitle, Skeleton,} from "@mui/material";
import React from "react";

export const NewAudienceOnboardingSkeleton = () => {
  return (
    <>
      <DialogTitle>
        <Skeleton variant="text" width={100} height="32px" />
      </DialogTitle>
      <DialogContent>
        <Skeleton variant="rounded" width="100%" height="48px" />
        <Skeleton variant="rounded" width="100%" height="48px" sx={{ mt: 2 }} />
        <Skeleton variant="rounded" width="100%" height="48px" sx={{ mt: 2 }} />
        <Skeleton variant="rounded" width="100%" height="48px" sx={{ mt: 2 }} />
      </DialogContent>
      <DialogActions>
        <Skeleton variant="rounded" width={86} height="36px" sx={{ mr: 1 }} />
        <Skeleton variant="rounded" width={86} height="36px" />
      </DialogActions>
    </>
  );
};
