import React, { FC } from "react";
import { sortBy } from "lodash";

import { Stack, Typography, SvgIcon } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { InsightCard } from "@/components";
import { IScore } from "@/interfaces";
import { mediaIcon } from "@/constants/icon-map";

interface InsightCardMediaProps {
  title: string;
  channels: IScore[];
}

export const InsightCardMedia: FC<InsightCardMediaProps> = ({
  title,
  channels,
}) => {
  return (
    <InsightCard title={title}>
      <Grid container spacing={4} mx={1} justifyContent="center">
        {sortBy(channels, "index")
          .reverse()
          .slice(0, 3)
          .map((channel) => {
            return (
              <Grid xs={4} key={channel.name}>
                <Stack spacing={2} alignItems="center">
                  <SvgIcon
                    component={mediaIcon(channel.name).ReactComponent}
                    inheritViewBox
                    sx={{ fontSize: "60px", mr: 2 }}
                  />
                  <Typography variant="caption" align="center">
                    {channel.name.split(" / ")[0]}
                  </Typography>
                </Stack>
              </Grid>
            );
          })}
      </Grid>
    </InsightCard>
  );
};
