import React from "react";

import { Card, CardHeader, CardContent } from "@mui/material";

interface InsightCardProps {
  title: string;
  children: React.ReactNode;
}

export const InsightCard: React.FC<InsightCardProps> = ({
  title,
  children,
}) => {
  return (
    <Card sx={{ height: 240, width: 360 }}>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: "h6" }}
        sx={{ pb: 0 }}
      />
      <CardContent
        sx={{
          height: 192,
          pb: "8px !important",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};
