import React from 'react';

import { useTheme, lighten } from '@mui/material/styles';
import {Tooltip as T} from "@mui/material";
import { TooltipProps as TP } from "@mui/material/Tooltip";

type TooltipProps = {
  title: React.ReactNode;
  placement: TP['placement'];
  children: React.ReactElement<any, any>;
};

export const Tooltip: React.FC<TooltipProps> = ({title, placement, children}) => {

  const theme = useTheme();

  return (
    <T
      title={title}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: lighten(theme.palette.background.default, 0.2),
            // backgroundColor: theme.palette.info.main,
            // color: theme.palette.info.contrastText,
            borderRadius: '4px',
            // padding: "8px",
            maxWidth: "200px",
            textAlign: "center",
          }
        }
      }}
    >
      {children}
    </T>
  )

}
