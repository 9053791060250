import {Box, Typography} from "@mui/material";

import {Chart as ChartJS, Filler, Legend, LineElement, PointElement, RadialLinearScale, Tooltip} from "chart.js";
import React from "react";
import {Radar} from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);


interface RadialChartProps {
  title: string;
  labels: string[],
  values: number[],
}

export const RadialChart: React.FC<RadialChartProps> = ({title, labels, values}) => {

  return (
    <Box>
      <Typography variant="h6" mb={2}>{title}</Typography>
      <Box height={250}>
        <Radar
          options={{
            animation: false,
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              r: {
                ticks: {
                  display: false,
                },
                grid: {
                  color: "rgba(132, 138, 146, 1)",
                  circular: true,
                },
                angleLines: {
                  color: "rgba(132, 138, 146, 1)",
                },
                pointLabels: {
                  color: "rgba(255, 255, 255, 1)",
                  // fontColor: radarData.labels.map(x => "#00B0CC")
                },
              },
            },
            plugins: {
              legend: {
                display: false,
                labels: {
                  color: "white",
                },
              },
              tooltip: {
                backgroundColor: "#2C3644",
                callbacks: {
                  label: function(context) {
                    return ' ' + context.parsed.r.toFixed(1);
                  }
                }
              }
            }
          }}
          data={{
            labels: labels,
            datasets: [
              {
                data: values,
                backgroundColor: "rgba(0, 176, 204, 0.4)",
                borderColor: "#00B0CC",
                borderWidth: 2,
              },
            ],
          }}
        />
      </Box>
    </Box>
  );
};
