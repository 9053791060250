import {GENDERS_MAP} from "@/constants";
import {IStarScore} from "@/interfaces";
import _ from "lodash";

/**
 * @description Returns a string summarising the age range
 * @param {string[] | undefined} age
 * @returns {string}
 */
export const getAgeSummary = (age: string[] | undefined) => {

	const options = ['18-24', '25-34', '35-44', '45+'];

	if (age && !age.includes('Any')) {

		// Calculate which age bands are sequential and combine them
		const ageIds: number[] = age.map(x => options.indexOf(x)).sort();
		const ageGroups: number[][] = ageIds.reduce((arr, val, i, a) => {
			if (!i || val !== a[i - 1] + 1) arr.push([]);
			arr[arr.length - 1].push(val);
			return arr;
		}, [] as number[][]);
		const ageBands = ageGroups.map((x) => {
			if (x.length > 1)
				return [options[x[0]].split('-')[0], options[x[x.length -1]].split('-').pop()].join('-')
			else
				return options[x[0]]
		})

		return `${ageBands?.join(' and ')}`
	} else return "Any"
}

/**
 * @description Returns a string summarising gender
 * @param {string[] | undefined} gender
 * @returns {string | undefined}
 */
export const getGenderSummary = (gender: string[] | undefined) =>
	gender?.includes('Any') || ['Male', 'Female'].every(val => gender?.includes(val))
		? "People"
		: gender?.map(g => GENDERS_MAP[g as keyof typeof GENDERS_MAP])
			.filter(Boolean)
			.join(' and ');


export const abbreviateNumber = (value: number) => {
	const SI_SYMBOL = ['', 'k', 'M', 'B', 'T', 'P', 'E'];
	const tier = Math.log10(Math.abs(value)) / 3 | 0;
	if (tier === 0) return value;
	const suffix = SI_SYMBOL[tier];
	const scale = Math.pow(10, tier * 3);
	const scaled = value / scale;
	return scaled.toFixed(1) + suffix;
}


export const formatPercentage = (value: number) => {
	return `${(value * 100).toFixed(0)}%`
}

export const titleCase = (value: string) => {
	return value.charAt(0).toUpperCase() + value.slice(1);
}


export const parseInterest = (interest: string): string => {
	return interest.replace('&', 'and').replace(',', '').replace('/', ' or ').replace('-', '').replace('+', '').replace(/  /g, ' ').replace(/ /g, '-').toLowerCase()
}


export const getMostCommonBrandType = (brands: IStarScore[] | undefined) => {
	const brandTypes = brands && brands.slice(0, 8).map(x =>
		x.tags.filter(y => y.type === 'type' && y.group === 'Brand').map(z => z.name)
	).flat();
	const brandTypeCounts = _(brandTypes).countBy().entries().maxBy(_.last);
	return brandTypeCounts && brandTypeCounts[0];
}
