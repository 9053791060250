import {
  InsightCardDemog,
  InsightCardImage,
  InsightCardInterest,
  InsightCardMedia,
} from "@/components";
import { IAudienceInsight, IAudienceSegment } from "@/interfaces";
import { formatPercentage } from "@/utils";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";

type SummaryViewProps = {
  insight: IAudienceInsight;
  selectedSegment: IAudienceSegment;
  onSegmentChange: (newValue: number) => void;
};

export const SummaryView: React.FC<SummaryViewProps> = ({
  insight,
  selectedSegment,
  onSegmentChange,
}) => {
  const { segments } = insight;

  return (
    <Grid xs={12}>
      <Grid xs={12}>
        <Typography variant="h4">{insight.audience_name}</Typography>
      </Grid>
      {segments && segments.length > 0 && (
        <Grid xs={12} py={2}>
          <FormControl variant="filled" size="small">
            <InputLabel id="view-select-label">Segment</InputLabel>
            <Select
              value={selectedSegment.id}
              onChange={(event) =>
                onSegmentChange(event.target.value as number)
              }
              label="Segments"
            >
              {segments.map((segment, index) => (
                <MenuItem key={segment.id} value={segment.id}>
                  {index === 0
                    ? segment.name
                    : `${segment.name} (${formatPercentage(segment.summary.audience_size.segment_percent)})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid xs={12} display="flex">
        <Stack direction="row" spacing={2}>
          <InsightCardDemog
            audienceSize={selectedSegment.summary.audience_size.social_size}
            modelledSize={selectedSegment.summary.audience_size.modelled_size}
            demographics={selectedSegment.summary.demographics}
          />
          <InsightCardInterest
            title="What interests them?"
            interests={selectedSegment.score.interests}
          />
          <InsightCardMedia
            title="How can I reach them?"
            channels={selectedSegment.summary.channels}
          />
        </Stack>
      </Grid>
      <Grid xs={12} display="flex">
        <Stack direction="row" spacing={2}>
          <InsightCardImage
            title="What brands do they prefer?"
            stars={selectedSegment.score.brands}
          />
          <InsightCardImage
            title="Where do they consume content?"
            stars={selectedSegment.score.media}
          />
          <InsightCardImage
            title="Who do they follow?"
            stars={selectedSegment.score.influencers}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};
