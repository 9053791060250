import { fetchData } from "@/api/utils";
import {
  ApiResponse,
  SegmentApiRequest,
  SegmentApiResponse,
  SegmentInsightApiResponse,
} from "@/interfaces";

const API_BASE_URL = process.env.REACT_APP_API_URL;

/**
 * Prepares the segment for an audience
 * @param {string} accessToken
 * @param {SegmentApiRequest} data
 * @returns {Promise<ApiResponse>}
 */
export const prepSegment = (
  accessToken: string,
  data: SegmentApiRequest
): Promise<ApiResponse<SegmentApiResponse>> => {
  const url = `${API_BASE_URL}/prep`;
  return postToSegmentAPI(url, accessToken, data);
};

/**
 * Creates the segment for an audience
 * @param {string} accessToken
 * @param {SegmentApiRequest} data
 * @returns {Promise<ApiResponse>}
 */
export const createSegment = (
  accessToken: string,
  data: SegmentApiRequest
): Promise<ApiResponse<SegmentApiResponse>> => {
  const url = `${API_BASE_URL}/segment`;
  return postToSegmentAPI(url, accessToken, data);
};

/**
 * Calculates the segment insights for an audience
 * @param {string} accessToken
 * @param {SegmentApiRequest} data
 * @returns {Promise<ApiResponse>}
 */
export const calculateSegmentInsights = (
  accessToken: string,
  data: SegmentApiRequest
): Promise<ApiResponse<SegmentInsightApiResponse>> => {
  const url = `${API_BASE_URL}/segment_insight`;
  return postToSegmentAPI(url, accessToken, data);
};

/**
 * Posts to the segment API
 * @param {string} url
 * @param {string} accessToken
 * @param {SegmentApiRequest} data
 * @returns {Promise<ApiResponse>}
 */
export const postToSegmentAPI = async (
  url: string,
  accessToken: string,
  data: SegmentApiRequest
): Promise<ApiResponse > => {
  try {
    return await fetchData({
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data,
    });
  } catch (error) {
    console.error(`Error posting to ${url}:`, error);

    if (error instanceof Error)
      throw new Error("Error processing segment operation: " + error.message);

    throw new Error("Error processing segment operation");
  }
};
