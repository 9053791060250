import React, { createContext, useContext, useState, ReactNode } from "react";

interface SnackbarState {
  open: boolean;
  message: string;
  type: "success" | "warning" | "error" | "info";
}

interface SnackbarContextProps {
  snackbar: SnackbarState;
  openSnackbar: (message: string, type?: SnackbarState["type"]) => void;
  closeSnackbar: () => void;
}

const SnackbarContext = createContext<SnackbarContextProps>(
  {} as SnackbarContextProps
);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: "",
    type: "info",
  });

  const openSnackbar = (
    message: string,
    type: SnackbarState["type"] = "info"
  ) => {
    setSnackbar({ open: true, message, type });
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SnackbarContext.Provider value={{ snackbar, openSnackbar, closeSnackbar }}>
      {children}
    </SnackbarContext.Provider>
  );
};
