import {getStars} from "@/api/list";
import {IStar} from "@/interfaces";
import {useEffect, useState} from "react";

export const useStarsList = (country: number, interests?: number[], types?: number[]) => {
	const [starsList, setStarsList] = useState<IStar[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		setLoading(true);
		getStars(country, interests, types)
			.then((response) => {
				setStarsList(response.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [interests, types]);

	return { starsList, loading, error };
}
