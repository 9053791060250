import { buildAudience, deleteAudience } from "@/api/audience";
import { buildInsight } from "@/api/insight";
import {
  calculateSegmentInsights,
  createSegment,
  prepSegment,
} from "@/api/segment";
import {
  AudienceCard,
  BasicIndicator,
  DeleteAudienceDialog,
  PageLayout,
} from "@/components";
import { ProgressModal, Step } from "@/components/organisms/progress-modal";
import {
  audienceBuildProgressSteps,
  audienceRebuildProgressSteps,
} from "@/constants";
import {
  BUILD_AUDIENCE,
  BUILD_SEGMENTS,
  useAudience,
} from "@/context/audience-context";
import { useSnackbar } from "@/context/snackbar-context";
import { InsightMode, ProgressStep } from "@/enums";
import { IAudience, ICountry, SegmentApiRequest } from "@/interfaces";
import { extractCountries, filterAudiences, sortAudiences } from "@/utils";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AddCircle,
  FilterList,
  ImportExport,
  Language,
  RestartAlt,
  Search,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const HomePage: React.FC = () => {
  const theme = useTheme();
  const { state, dispatch } = useAudience();
  const { openSnackbar } = useSnackbar();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedAudience, setSelectedAudience] = useState<IAudience | null>(
    null
  );
  const [audienceBuildingStates, setAudienceBuildingStates] = useState<{
    [key: string]: boolean;
  } | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("");
  const [filterBy, setFilterBy] = useState<string[]>([]);
  const [rows, setRows] = useState<IAudience[]>([]);
  const [error, setError] = useState<string | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const countryList = extractCountries(state.audiences);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | undefined>(
    undefined
  );

  const sortOptions = [
    { name: "default", label: "Created Date" },
    { name: "name", label: "Audience Name" },
    { name: "size", label: "Audience Size" },
    { name: "date", label: "Run Date" },
  ];

  const filterOptions = [
    { name: "ready", label: "Insight Ready", mode: InsightMode.READY },
    { name: "stale", label: "Insight Outdated", mode: InsightMode.STALE },
    {
      name: "missing",
      label: "Insight Missing",
      mode: InsightMode.NEEDS_TO_RUN,
    },
  ];

  const [isAudienceRebuilding, setIsAudienceRebuilding] = useState(false);
  const [showAudienceRebuildProgress, setShowAudienceRebuildProgress] =
    useState(false);
  const [audienceRebuildStep, setAudienceRebuildStep] = useState<ProgressStep>(
    ProgressStep.Idle
  );
  const [steps, setSteps] = useState<Step[]>(audienceRebuildProgressSteps);

  useEffect(() => {
    const filters = filterOptions
      .filter((option) => filterBy.includes(option.name))
      .map((x) => x.mode);
    const audiences = filterAudiences(
      sortAudiences(state.audiences, sortBy),
      searchText,
      filters,
      selectedCountry
    );
    setRows(audiences);
  }, [state.audiences, searchText, sortBy, filterBy, selectedCountry]);

  const handleEdit = (id: number | undefined) => {
    history.push(`/audience/${id}`);
  };

  const handleDelete = async () => {
    const accessToken = await getAccessTokenSilently();
    if (selectedAudience && selectedAudience.id) {
      try {
        const { data } = await deleteAudience(accessToken, selectedAudience.id);
        dispatch({ type: "SET_AUDIENCE", payload: data });
        setOpenDeleteDialog(false);
        openSnackbar("Audience deleted successfully", "success");
      } catch (e) {
        console.log(e);
        openSnackbar("There was an error deleting your audience", "error");
      }
    }
  };

  const handleCreateAudience = () => {
    history.push("/audience/create");
  };

  const handleOnShowInsight = (id: number | undefined) => {
    history.push(`/audience/${id}/insight`);
  };

  const handleBuildAudience = async (audience: IAudience) => {
    const accessToken = await getAccessTokenSilently();

    if (audience.id) {
      try {
        // Build the audience
        setError(null);
        setAudienceBuildingStates((prevStates) => ({
          ...prevStates,
          [audience.id || ""]: true,
        }));

        const response = await buildAudience(accessToken, audience);

        // If the audience is big enough build the insight
        if (response.data.audience_size > 0) {
          const { data } = await buildInsight(
            accessToken,
            audience.id,
            audience.country,
            response.data.request_id
          );

          // Update the context
          if (data) {
            dispatch({
              type: "BUILD_AUDIENCE",
              payload: {
                audienceId: audience.id,
                runId: data.run_id,
                runDate: data.run_date,
                modelledCount: data.modelled_count,
                requestId: data.request_id,
                socialCount: data.social_count,
              },
            });
          }
        } else {
          setError(
            "Building your audience returned no results, please refine the query criteria"
          );
        }
        setAudienceBuildingStates((prevStates) => ({
          ...prevStates,
          [audience.id || ""]: false,
        }));

        openSnackbar("Audience built successfully", "success");
      } catch (e) {
        setAudienceBuildingStates((prevStates) => ({
          ...prevStates,
          [audience.id || ""]: false,
        }));
        openSnackbar("There was an error building your audience", "error");
        setError("There was an error building your audience");
      }
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value || "");
  };

  const handleSortSelect = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as string);
  };

  const handleFilterSelect = (event: SelectChangeEvent<typeof filterBy>) => {
    const value = event.target.value;
    setFilterBy(typeof value === "string" ? value.split(",") : value);
  };

  const handleCountrySelect = (event: SelectChangeEvent) => {
    setSelectedCountry(
      countryList.find((x) => x.name === (event.target.value as string))
    );
  };

  const handleReset = () => {
    setSearchText("");
    setFilterBy([]);
    setSortBy("");
    setSelectedCountry(undefined);
  };

  const handleRebuildAudience = async (audience: IAudience) => {
    const accessToken = await getAccessTokenSilently();

    if (audience.id) {
      // check if segment has been built for this audience, if not, change the progress steps to audience build only
      if (!audience.segment_run_id) {
        setSteps(audienceBuildProgressSteps);
      }

      try {
        // Build the audience
        setShowAudienceRebuildProgress(true);
        setIsAudienceRebuilding(true);
        setAudienceRebuildStep(ProgressStep.BuildingAudience);
        const response = await buildAudience(accessToken, audience);

        // If the audience is big enough build the insight
        if (response.data.audience_size > 0) {
          // setAudienceBuildStep(ProgressStep.GettingInsights);
          const { data: audienceInsight, error } = await buildInsight(
            accessToken,
            audience.id,
            audience.country,
            response.data.request_id
          );

          if (error) {
            openSnackbar(
              "There was an error rebuilding your audience",
              "error"
            );
            setIsAudienceRebuilding(false);
            setShowAudienceRebuildProgress(false);
            setAudienceRebuildStep(ProgressStep.Idle);
            console.log(error);
            return;
          }

          // Update the context and local state
          if (audienceInsight) {
            dispatch({
              type: BUILD_AUDIENCE,
              payload: {
                audienceId: audience.id,
                requestId: audienceInsight.request_id,
                runDate: audienceInsight.run_date,
                runId: audienceInsight.run_id,
                modelledCount: audienceInsight.modelled_count,
                socialCount: audienceInsight.social_count,
              },
            });

            // rebuild the segments
            if (
              audience.id &&
              audienceInsight.request_id &&
              audienceInsight.run_id &&
              audience.segment_run_id
            ) {
              try {
                const segmentRequest: SegmentApiRequest = {
                  audience_id: audience.id,
                  country: audience.country,
                  request_id: audienceInsight.request_id,
                  run_id: audienceInsight.run_id,
                };

                // Prep segment
                setAudienceRebuildStep(ProgressStep.PreparingSegment);
                const prepSegmentData = await prepSegment(
                  accessToken,
                  segmentRequest
                );

                // Create segment
                if (prepSegmentData.data?.request_id) {
                  setAudienceRebuildStep(ProgressStep.BuildingSegment);

                  const createSegmentData = await createSegment(
                    accessToken,
                    segmentRequest
                  );

                  // Calculate segment insights
                  if (createSegmentData.data?.request_id) {
                    setAudienceRebuildStep(ProgressStep.GettingInsights);
                    const segmentInsightsData = await calculateSegmentInsights(
                      accessToken,
                      segmentRequest
                    );

                    if (segmentInsightsData.data) {
                      setAudienceRebuildStep(ProgressStep.Completed);

                      dispatch({
                        type: BUILD_SEGMENTS,
                        payload: {
                          audienceId: audience.id,
                          segmentRunId:
                            segmentInsightsData.data?.segment_run_id || 0,
                          segmentRunDate:
                            segmentInsightsData.data?.segment_run_date || "",
                          segmentCount: segmentInsightsData.data?.segment_count,
                        },
                      });
                    }
                  }
                }
              } catch (error) {
                const errorMessage =
                  error instanceof Error
                    ? error.message
                    : "There was an error building your segments";
                openSnackbar(errorMessage, "error");
                setShowAudienceRebuildProgress(false);
                setIsAudienceRebuilding(false);
                setAudienceRebuildStep(ProgressStep.BuildingSegmentError);
              } finally {
                setShowAudienceRebuildProgress(false);
                setIsAudienceRebuilding(false);
              }
            }

            openSnackbar("Audience rebuilt successfully", "success");

            history.push(`/audience/${audience.id}/insight`);
          } else {
            openSnackbar(
              "Re-building your audience returned no results, please refine the query criteria and try again",
              "error"
            );
            setIsAudienceRebuilding(false);
            setShowAudienceRebuildProgress(false);
          }
        }
      } catch (e) {
        openSnackbar("There was an error rebuilding your audience", "error");
        setAudienceRebuildStep(ProgressStep.BuildingAudienceError);
        setIsAudienceRebuilding(false);
        setShowAudienceRebuildProgress(false);
      }
    }
  };

  return (
    <PageLayout>
      <Grid
        container
        spacing={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        px="5%"
      >
        <Grid xs={12}>
          <Typography variant={"h4"}>My Audiences</Typography>
        </Grid>

        <Grid xs={12}>
          <Typography variant="body1">
            Select and manage any saved audiences you have created.
          </Typography>
        </Grid>

        <Grid xs={12} container spacing={1} alignItems="center" mb={1}>
          <Grid>
            <TextField
              id="search-bar"
              variant="outlined"
              size="small"
              color="primary"
              value={searchText}
              onChange={handleSearch}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
                sx: {
                  maxWidth: "200px",
                  "& > fieldset": {
                    borderColor:
                      searchText !== "" ? theme.palette.primary.main : "",
                  },
                },
              }}
            />
          </Grid>
          <Grid>
            <Select
              multiple
              size="small"
              value={filterBy}
              onChange={handleFilterSelect}
              displayEmpty
              renderValue={(value: string[]) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <FilterList fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography
                      color={value.length > 0 ? "textPrimary" : "textSecondary"}
                    >
                      Filter
                    </Typography>
                  </Box>
                  {value.length > 0 && (
                    <Box display="flex" flexDirection="row">
                      {filterOptions
                        .filter((option) => value.includes(option.name))
                        .map((mode) => (
                          <BasicIndicator
                            key={mode.name}
                            mode={mode.mode}
                            margin={0.5}
                          />
                        ))}
                    </Box>
                  )}
                </Box>
              )}
              sx={{
                minWidth: "200px",
                "& > fieldset": {
                  borderColor:
                    filterBy.length > 0 ? theme.palette.primary.main : "",
                },
              }}
            >
              {filterOptions.map((option) => (
                <MenuItem key={option.name} value={option.name}>
                  <BasicIndicator mode={option.mode} margin={1} />
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid>
            <Select
              size="small"
              value={sortBy}
              onChange={handleSortSelect}
              displayEmpty
              renderValue={(value: string) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <ImportExport fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography
                      color={value.length > 0 ? "textPrimary" : "textSecondary"}
                    >
                      Sort
                    </Typography>
                  </Box>
                  {value !== "" && (
                    <Typography color="primary" sx={{ ml: 2 }}>
                      {sortOptions.find((x) => x.name === value)?.label}
                    </Typography>
                  )}
                </Box>
              )}
              sx={{
                minWidth: "200px",
                "& > fieldset": {
                  borderColor: sortBy !== "" ? theme.palette.primary.main : "",
                },
              }}
            >
              {sortOptions.map((mode) => (
                <MenuItem key={mode.name} value={mode.name}>
                  {mode.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid>
            <Select
              size="small"
              value={selectedCountry?.name || ""}
              onChange={handleCountrySelect}
              displayEmpty
              renderValue={(value: string) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Language fontSize="small" sx={{ mr: 1 }} />
                    <Typography
                      color={value.length > 0 ? "textPrimary" : "textSecondary"}
                    >
                      Country
                    </Typography>
                  </Box>
                  {value !== "" && (
                    <Typography color="primary" sx={{ ml: 2 }}>
                      {countryList.find((x) => x.name === value)?.name}
                    </Typography>
                  )}
                </Box>
              )}
              sx={{
                minWidth: "200px",
                "& > fieldset": {
                  borderColor: sortBy !== "" ? theme.palette.primary.main : "",
                },
              }}
            >
              {countryList.map((country) => (
                <MenuItem key={country.id} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid>
            <Tooltip title={"Reset Filters"}>
              <IconButton onClick={handleReset} sx={{ borderRadius: "6px" }}>
                <RestartAlt color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid xs={12} container spacing={3}>
          <Grid>
            <Card
              variant="outlined"
              sx={{
                backgroundColor: "transparent",
                width: "250px",
                height: "200px",
              }}
            >
              <CardActionArea
                onClick={handleCreateAudience}
                sx={{ height: "100%" }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AddCircle color="primary" sx={{ fontSize: 50 }} />
                  <Typography variant="h6" mt={1}>
                    Create New Audience
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          {state.isLoading
            ? Array.from(new Array(10)).map((_, index) => (
                <Grid key={index}>
                  <Skeleton
                    key={index}
                    variant="rounded"
                    width={250}
                    height={200}
                  />
                </Grid>
              ))
            : rows.map((audience) => {
                const isBuilding =
                  typeof audience.id === "number" &&
                  audienceBuildingStates &&
                  audienceBuildingStates[audience.id.toString()];

                return (
                  <Grid key={audience.id}>
                    <AudienceCard
                      audience={audience}
                      isBuilding={isBuilding}
                      onEdit={handleEdit}
                      onBuildAudience={handleBuildAudience}
                      onRebuildAudience={handleRebuildAudience}
                      onSelectedAudience={setSelectedAudience}
                      onShowDeleteDialog={() => setOpenDeleteDialog(true)}
                      onShowInsight={handleOnShowInsight}
                    />
                  </Grid>
                );
              })}
        </Grid>

        {error && (
          <Grid xs={11} mt={2} pl={2}>
            <Alert
              severity="error"
              sx={{ background: theme.palette.background.paper }}
            >
              <AlertTitle>Build Error</AlertTitle>
              {error}
            </Alert>
          </Grid>
        )}

        <DeleteAudienceDialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={handleDelete}
          audienceName={selectedAudience?.name || ""}
        />
      </Grid>

      {isAudienceRebuilding && (
        <ProgressModal
          open={showAudienceRebuildProgress}
          onClose={() => setShowAudienceRebuildProgress(false)}
          progressStep={audienceRebuildStep}
          title={"Rebuilding your audience"}
          description={
            "This may take a few minutes, please do not close this window."
          }
          steps={steps}
        />
      )}
    </PageLayout>
  );
};
