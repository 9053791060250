import {IndustrySectionSkeleton} from '@/components';
import {QUERY_GROUP_STEP_HEIGHT} from "@/constants";
import {industryIcon} from '@/constants/icon-map';
import {useIndustryList} from '@/hooks/useIndustryList';
import {selectedCardStyle} from '@/styles/common';

import {Box, Card, CardActionArea, CardContent, SvgIcon, Typography,} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, {FC, memo} from 'react';

interface IndustrySectionProps {
	industry: number | null;
	onIndustryChange: (industry: number) => void;
}

const industryCardContentStyle = {
	height: '100%',
	display: 'flex',
	flexDirection: "column",
	alignContent: 'center',
	justifyContent: 'center'
}

export const IndustrySection: FC<IndustrySectionProps> = memo(({industry, onIndustryChange}) => {
	const {loading, industryList} = useIndustryList();

	if (loading) return <IndustrySectionSkeleton/>;

	return (
		<Grid container spacing={2} sx={{height: QUERY_GROUP_STEP_HEIGHT}}>
			{industryList.map((item) => (
				<Grid key={item.id} xs={6} sm={6} md={4} lg={3}>
					<Card sx={industry === item.id ? {height: '140px', ...selectedCardStyle} : {height: '140px'}}>
						<CardActionArea onClick={() => onIndustryChange(item.id)} sx={{height: '100%'}}>
							<CardContent
								sx={item.id === 0 ? {...industryCardContentStyle, textAlign: 'center'} : industryCardContentStyle}>
								<Box display="flex" flexDirection="row" justifyContent={item.id === 0 ? 'center' : 'flex-start'}>
									{item.id !== 0 && (
										<SvgIcon
											component={industryIcon(item.name).ReactComponent}
											inheritViewBox
											sx={{fontSize: '30px', mr: 2}}
										/>
									)}
									<Typography gutterBottom variant="h6" fontWeight="bold" noWrap>
										{item.name}
									</Typography>
								</Box>
								<Typography
									variant="body2"
									sx={{
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										display: '-webkit-box',
										WebkitLineClamp: '3',
										WebkitBoxOrient: 'vertical',
									}}>
									{item.description}
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
			))}
		</Grid>
	);
});
