import { fetchData } from "@/api/utils";
import { AxiosRequestConfig } from "axios";

export const getCountries = async () => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/list/countries`,
  };

  return fetchData(config);
};
