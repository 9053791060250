import {CustomNoDataOverlay} from "@/styles/components/data-grid";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import {Box, Icon, SvgIcon, Typography} from "@mui/material";
import {DataGrid, GridColDef, GridValueFormatterParams} from '@mui/x-data-grid';
import {GridRenderCellParams} from "@mui/x-data-grid/models/params/gridCellParams";
import React from "react";
import {interestIcon} from "@/constants/icon-map";

interface TableRow {
	name: string;
	penetration: number;
	index: number;
	growth: number;
}


interface TagTableProps {
	title: string;
	rows: TableRow[];
}

export const TagTable: React.FC<TagTableProps> = (
	{
		title,
		rows
	}) => {

	const renderTag = (params: GridRenderCellParams) => {
		return (
			<Box display="flex" flexDirection="row" alignItems="center">
				<SvgIcon
					component={interestIcon(params.value).ReactComponent}
					inheritViewBox
					sx={{fontSize: '30px'}}
				/>
				<Typography ml={2}>{params.value}</Typography>
			</Box>
		)
	}

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Name',
			flex: 3,
			sortable: false,
			disableColumnMenu: true,
			renderCell: renderTag
		},
		{
			field: 'penetration',
			headerName: 'Reach',
			disableColumnMenu: true,
			flex: 1,
			valueFormatter: (params:GridValueFormatterParams<number> ) => {
				return `${(params.value * 100).toFixed(1)}%`
			}
		},
		{
			field: 'index',
			headerName: 'Affinity',
			disableColumnMenu: true,
			flex: 1,
			valueFormatter: (params:GridValueFormatterParams<number> ) => {
				return `${params.value.toFixed(1)}`
			}
		},
		{
			field: 'growth',
			headerName: 'Growth',
			disableColumnMenu: true,
			flex: 1,
			valueFormatter: (params:GridValueFormatterParams<number> ) => {
				return `${(params.value * 100).toFixed(1)}%`
			}
		},
	];

	return (
		<>
			<Typography variant="h6" mb={2}>{title}</Typography>
			<Box height={500}>
				<DataGrid
					columns={columns}
					rows={rows}
					slots={{
						noRowsOverlay: () => (
							<CustomNoDataOverlay text="No audiences found"/>
						)
					}}
					hideFooterPagination
					hideFooter
					disableRowSelectionOnClick
				/>
			</Box>
		</>
	);
}
