import {Checkbox, FormControlLabel} from "@mui/material";
import React, {FC} from "react";

interface AgeCheckboxProps {
  labels: string[];
  values: string[] | undefined;
  onValueChange: (values: string[]) => void;
}

export const AgeCheckbox: FC<AgeCheckboxProps> = ({
  labels,
  values,
  onValueChange,
}) => {
  const handleCheckboxChange = (
    value: string,
    selectedValues: string[],
    setSelectedValues: (values: string[]) => void
  ) => {
    // If the value is 'ANY', then we want to remove all other values else we want to remove 'ANY'
    if (value === "Any") {
      setSelectedValues([value]);
      return;
    }

    // Remove the 'Any' value if present in the selected values
    const selectedValuesWithoutAny = selectedValues.filter(
      (item) => item !== "Any"
    );

    if (selectedValues.includes(value)) {
      const newSelectedValues = selectedValuesWithoutAny.filter(
        (item) => item !== value
      );
      setSelectedValues(newSelectedValues);

      // If nothing ends up being selected default back to 'Any'
      if (newSelectedValues.length === 0) {
        setSelectedValues(["Any"]);
      }
    } else {
      setSelectedValues([...selectedValuesWithoutAny, value]);
    }
  };

  return (
    <>
      <FormControlLabel
        label={labels[0]}
        control={
          <Checkbox
            checked={values?.includes(labels[0])}
            onChange={() =>
              handleCheckboxChange(labels[0], values || [], onValueChange)
            }
          />
        }
      />
      {labels.slice(1).map((label) => (
        <FormControlLabel
          key={label}
          label={label}
          control={
            <Checkbox
              checked={values?.includes(label)}
              onChange={() =>
                handleCheckboxChange(label, values || [], onValueChange)
              }
            />
          }
        />
      ))}
    </>
  );
};
