import { fetchData } from "@/api/utils";
import {
  ApiResponse,
  AudienceInsightApiResponse,
	DataExportApiResponse,
  InsightApiResponse,
  InsightReportApiResponse,
} from "@/interfaces";
import { AxiosRequestConfig } from "axios";

export const buildInsight = async (
  accessToken: string,
  audience_id: number,
  country: number,
  request_id: string
): Promise<ApiResponse<AudienceInsightApiResponse>> => {
  const config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/insight`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: { audience_id, country, request_id },
  };

  return fetchData(config);
};

export const getInsight = async (
  accessToken: string,
  id: number
): Promise<ApiResponse<InsightApiResponse>> => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/insight/${id}`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

export const getInsightReport = async (
  accessToken: string,
  id: number
): Promise<ApiResponse<InsightReportApiResponse>> => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/export/insight/${id}`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

export const getDataExport = async (
  accessToken: string,
  id: number
): Promise<ApiResponse<DataExportApiResponse>> => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/export/data/${id}`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};
