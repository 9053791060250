import { Box, Typography, Stack } from "@mui/material";
import React from "react";

import { formatPercentage } from "@/utils";
import { IScore } from "@/interfaces";

type SegmentDemogsProps = {
  gender: IScore;
  age: IScore;
};

export const SegmentDemogs: React.FC<SegmentDemogsProps> = ({
  gender,
  age,
}) => {
  return (
    <Stack gap={1}>
      <Typography variant="caption" color={"text.secondary"}>
        Demographics
      </Typography>
      <Box display={"flex"} flexDirection={"row"} gap={1} height={60}>
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Typography variant="caption" color={"text.secondary"}>
            Age
          </Typography>
          <Typography variant="caption" color={"text.secondary"}>
            Gender
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Typography variant="caption">
            {age.name}
          </Typography>
          <Typography variant="caption" textTransform={"capitalize"}>
            {gender.name}
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Typography variant="caption">
            {formatPercentage(age.penetration)}
          </Typography>
          <Typography variant="caption">
            {formatPercentage(gender.penetration)}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};
