import {getInsight, getInsightReport, getDataExport} from "@/api/insight";
import {
  ComparisonView,
  DetailedView,
  PageLayout,
  SummaryView,
  Tooltip
} from "@/components";
import { InsightPageSkeleton } from "@/components/atoms";
import { insightViews, insightViewsSegment } from "@/constants";

import {DataExportApiResponse, IAudienceInsight, IAudienceSegment, InsightReportApiResponse} from "@/interfaces";
import { useAuth0 } from "@auth0/auth0-react";
import { ChevronLeft, Download } from "@mui/icons-material";

import { Alert, AlertTitle, Button, Tab, Tabs, Box, Stack } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

export const InsightPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [hasSegments, setHasSegments] = useState<boolean>(false);
  const [hasTopics, setHasTopics] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [insight, setInsight] = useState<IAudienceInsight | null>(null);

  const [selectedSegment, setSelectedSegment] = useState<IAudienceSegment>(
    {} as IAudienceSegment
  );

  const [selectedView, setSelectedView] = useState<string>("Summary");

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      return await getInsight(accessToken, Number(id));
    };

    fetchData().then((response) => {
      if (response.data) {
        setInsight(response.data);

        const audienceSegment: IAudienceSegment = {
          id: response.data.audience_id,
          summary: response.data.summary,
          score: response.data.score,
          name: "Audience",
        };

        // if we have segments, add the audience to the beginning
        if (response.data.segments) {
          setHasSegments(true);
          response.data.segments.unshift(audienceSegment);
        }

        // Check whether topics are available for export
        if (response.data.score.topics) {
          setHasTopics(true);
        }

        // Set the audience as the selected segment
        setSelectedSegment(audienceSegment);
      }
      setIsLoading(false);
      response?.error && setError(response.error.message);
    });
  }, []);

  const downloadData = (data: InsightReportApiResponse | DataExportApiResponse, fileName: string) => {
    // Create file
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create an "a" HTML element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  const onInsightReport = () => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      return await getInsightReport(accessToken, Number(id));
    };
    setIsDownloading(true);
    fetchData().then((response) => {
      response.data && downloadData(response.data, `${insight?.audience_name} Insight Report` || 'Insight Report');
      setIsDownloading(false);
      response?.error && setError(response.error.message);
    })
  }

  const onDataExport = () => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      return await getDataExport(accessToken, Number(id));
    };
    setIsDownloading(true);
    fetchData().then((response) => {
      response.data && downloadData(response.data, `${insight?.audience_name} Data Export` || 'Data Export');
      setIsDownloading(false);
      response?.error && setError(response.error.message);
    })
  }

  const onBackToAudiences = () => {
    history.push(`/audience/${id}`);
  };

  const handleSegmentChange = (segment: number) => {
    const newSegment = insight?.segments.find((value) => value.id === segment);
    if (newSegment) setSelectedSegment(newSegment);
  };

  const renderInsightView = () => {
    if (!insight) return null;

    switch (selectedView) {
      case "Summary":
        return (
          <Grid xs={12}>
            <SummaryView
              insight={insight}
              selectedSegment={selectedSegment}
              onSegmentChange={handleSegmentChange}
            />
          </Grid>
        );
      case "Detailed":
        return (
          <DetailedView
            insight={insight}
            selectedSegment={selectedSegment}
            onSegmentChange={handleSegmentChange}
            error={error}
          />
        );
      case "Comparison":
        return (
          <Grid xs={12}>
            <ComparisonView segments={insight.segments} />
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <PageLayout>
      <Grid container spacing={2} px={1} justifyContent="center" alignItems="center">
        {isLoading ? (
          <InsightPageSkeleton />
        ) : error ? (
          <Grid xs={12}>
            <Alert
              severity="error"
              sx={{ background: theme.palette.background.paper }}
            >
              <AlertTitle>No Insights Found</AlertTitle>
              No Insights found for this audience. Please check that the
              audience has been built successfully and that audience insights
              are ready to view.
            </Alert>
          </Grid>
        ) : (
          insight && (
            <>
              <Grid xs={2}>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<ChevronLeft />}
                  onClick={onBackToAudiences}
                  disabled={isDownloading}
                >
                  Back to Audience
                </Button>
              </Grid>
              <Grid xs={10}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Tabs
                    value={selectedView}
                    onChange={(_event, newValue) => setSelectedView(newValue)}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {(hasSegments ? insightViewsSegment : insightViews).map((view, index) => {
                      return <Tab key={index} value={view} label={view} disabled={isDownloading}/>;
                    })}
                  </Tabs>
                  {hasTopics &&
                    <Stack direction="row" spacing={3}>
                      <Tooltip placement="bottom" title="Download an insight report for TopicDNA">
                        <LoadingButton
                          size="small"
                          variant="outlined"
                          startIcon={<Download />}
                          onClick={onInsightReport}
                          loading={isDownloading}
                        >
                          Insight Report
                        </LoadingButton>
                      </Tooltip>
                      <Tooltip placement="bottom" title="Download an data export for TopicDNA">
                        <LoadingButton
                          size="small"
                          variant="outlined"
                          startIcon={<Download />}
                          onClick={onDataExport}
                          loading={isDownloading}
                        >
                          Data Export
                        </LoadingButton>
                      </Tooltip>
                    </Stack>
                  }
                </Box>
              </Grid>
              {renderInsightView()}
            </>
          )
        )}
      </Grid>
    </PageLayout>
  );
};
