import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Grid } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

import { Spinner } from "@/components";

export const CallbackPage: React.FC = () => {
  const { isLoading, isAuthenticated, error } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      history.replace("/");
    }
  }, [isLoading, isAuthenticated, history]);

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: "100%",
        }}
      >
        <Spinner />
      </Grid>
    );
  }

  if (error) {
    return (
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Error
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>{error.message}</span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="page-layout">
      <div className="page-layout-content" />
    </div>
  );
};
