import {
  ChangeQueryModeWarningDialog,
  InterestQuerySection,
  StarsQuerySection,
} from "@/components";
import { starsFilterOperators } from "@/constants";
import { QueryMode } from "@/enums";
import { InterestQuery, IStar } from "@/interfaces";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { FC, useEffect } from "react";

interface ConfigureQuerySectionProps {
  queryMode: string;
  interests: number[];
  country: number;
  query: InterestQuery[];
  selectedStars: IStar[] | null;
  operator: string;
  limiter: number;
  onStarChange: (star: IStar) => void;
  onAllStarChange: (stars: IStar[]) => void;
  onStarRemove: (id: IStar) => void;
  onClearStars: () => void;
  onLimiterChange: (limiter: number) => void;
  onInterestChange: (interest: number) => void;
  onIndustryChange: (industry: number) => void;
  onOperatorChange: (operator: string) => void;
  onQueryOperatorChange: (queryIndex: number, operator: string) => void;
  onQueryModeChange: (mode: string) => void;
  onQueryTypeChange: (queryIndex: number, starTypes: number[]) => void;
  onQueryLimiterChange: (queryIndex: number, limiter: number) => void;
}

export const ConfigureQuerySection: FC<ConfigureQuerySectionProps> = ({
  queryMode,
  interests,
  country,
  query,
  selectedStars,
  operator,
  limiter,
  onStarChange,
  onAllStarChange,
  onStarRemove,
  onClearStars,
  onLimiterChange,
  onInterestChange,
  onIndustryChange,
  onOperatorChange,
  onQueryModeChange,
  onQueryOperatorChange,
  onQueryTypeChange,
  onQueryLimiterChange,
}) => {
  const isNewInterestQuery =
    queryMode === QueryMode.INTEREST && starsFilterOperators.includes(operator);
  const isNewStarQuery =
    (queryMode === QueryMode.STAR && selectedStars?.length === 0) ||
    selectedStars === null;
  const [displayWarning, setDisplayWarning] = React.useState(false);
  const [newQueryMode, setNewQueryMode] = React.useState<string>(queryMode);

  useEffect(() => {
    if (isNewInterestQuery) onOperatorChange("AND");
    if (isNewStarQuery) onOperatorChange("ANY");
  }, [queryMode]);

  const handleModeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newMode: QueryMode
  ) => {
    if (newMode === QueryMode.INTEREST && selectedStars && selectedStars.length > 0) {
      setDisplayWarning(true);
      setNewQueryMode(newMode);
    } else if (newMode === QueryMode.STAR && query.some(q => q.operator !== 'ANY')) {
      setDisplayWarning(true);
      setNewQueryMode(newMode);
    } else {
      onQueryModeChange(newMode);
    }
  };

  const onClose = () => setDisplayWarning(false);
  const onConfirm = () => {
    onQueryModeChange(newQueryMode);
    setDisplayWarning(false);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ height: "100%" }}
        justifyContent="center"
        alignContent="flex-start"
      >
        <Grid
          xs={12}
          display="flex"
          flexDirection="row"
          alignItems="center"
          mb={2}
        >
          <ToggleButtonGroup
            exclusive
            size="small"
            color="primary"
            value={queryMode}
            onChange={handleModeChange}
          >
            <ToggleButton value="interest">Interest</ToggleButton>
            <ToggleButton value="star">Account</ToggleButton>
          </ToggleButtonGroup>
          <Typography variant="body1" ml={2}>
            Configure your query by building an audience using{" "}
            {queryMode === "star" ? "Social Accounts" : "Interests"}
          </Typography>
        </Grid>
        {queryMode === QueryMode.STAR && (
          <StarsQuerySection
            interests={interests}
            country={country}
            selectedStars={selectedStars}
            operator={operator}
            limiter={limiter}
            onOperatorChange={onOperatorChange}
            onClearStars={onClearStars}
            onAllStarChange={onAllStarChange}
            onStarChange={onStarChange}
            onLimiterChange={onLimiterChange}
            onStarRemove={onStarRemove}
            onInterestChange={onInterestChange}
            onIndustryChange={onIndustryChange}
          />
        )}
        {queryMode === QueryMode.INTEREST && (
          <InterestQuerySection
            interests={interests}
            country={country}
            operator={operator}
            query={query}
            onOperatorChange={onOperatorChange}
            onQueryOperatorChange={onQueryOperatorChange}
            onQueryLimiterChange={onQueryLimiterChange}
            onQueryTypeChange={onQueryTypeChange}
          />
        )}
      </Grid>

      <ChangeQueryModeWarningDialog
        open={displayWarning}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    </>
  );
};
