import {
  ConfigureQuerySection,
  IndustrySection,
  InterestSelection,
  QueryGroupSummary,
  UnsavedChangesDialog,
} from "@/components";
import { defaultQueryGroup, QUERY_GROUP_STEP_FORM_HEIGHT } from "@/constants";
import { QueryMode } from "@/enums";
import { IAudience, IQueryGroup, IStar } from "@/interfaces";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

interface StepProps {
  step?: number;
  title: string;
  description?: string;
  component: React.ReactNode;
}

interface QueryGroupStepFormProps {
  currentQueryGroupIndex: number | null;
  audience: IAudience;
  open: boolean;
  onClose: () => void;
  onConfirm: (queryGroup: IQueryGroup) => void;
}

export const QueryGroupStepForm: FC<QueryGroupStepFormProps> = ({
  currentQueryGroupIndex,
  audience,
  onClose,
  open,
  onConfirm,
}) => {
  const [queryGroup, setQueryGroup] = useState<IQueryGroup>(defaultQueryGroup);
  const [activeStep, setActiveStep] = useState(0);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const stepValidators = [
    () => queryGroup.industry || queryGroup.industry === 0,
    () => queryGroup.interests.length > 0,
    () =>
      queryGroup.query_mode === QueryMode.INTEREST ||
      (queryGroup.stars &&
        queryGroup.stars.length > 0 &&
        queryGroup.query_mode === QueryMode.STAR),
    () => queryGroup.summary !== null,
  ];
  const history = useHistory();

  useEffect(() => {
    const isCurrentStepValid = stepValidators[activeStep]();
    if (isCurrentStepValid) setIsNextDisabled(false);
    else setIsNextDisabled(true);
  }, [activeStep, queryGroup]);

  useEffect(() => {
    if (currentQueryGroupIndex === null) {
      setQueryGroup(defaultQueryGroup);
      return;
    }
    setQueryGroup(
      audience.query_groups[currentQueryGroupIndex] || defaultQueryGroup
    );
    setActiveStep(steps.length - 1); // if queryGroup exists, set activeStep to last step
  }, [currentQueryGroupIndex, audience]);

  const updateQueryGroup = (updatedProperties: Partial<IQueryGroup>) => {
    setQueryGroup((prevState) => ({
      ...prevState,
      ...updatedProperties,
      hasChanges: true,
    }));
  };

  const handleIndustryChange = (industry: number) => {
    // if industry is changed, clear everything
    if (industry !== queryGroup.industry && industry !== 0) {
      updateQueryGroup(defaultQueryGroup);
    }
    updateQueryGroup({ industry });
  };

  const handleInterestChange = (interest: number) => {
    setQueryGroup((prevState) => {
      if (!prevState)
        return {
          ...defaultQueryGroup,
          interests: [interest],
          query: [
            {
              operator: "ANY",
              type_filter: [],
              limiter: 1,
              interest_id: interest,
            },
          ],
          hasChanges: true,
        };
      return {
        ...prevState,
        interests: [...prevState.interests, interest],
        query: [
          ...prevState.query,
          {
            operator: "ANY",
            type_filter: [],
            limiter: 1,
            interest_id: interest,
          },
        ],
        hasChanges: true,
      };
    });
  };

  const handleInterestDelete = (interest: number) => {
    // when interest is deleted, the interest, query and stars should be cleared from the selected interests
    updateQueryGroup({
      interests: queryGroup.interests.filter((c) => c !== interest),
      query: queryGroup.query.filter((query) => query.interest_id !== interest),
      // refactor this later to remove stars from stars query section when interest is deleted
      stars: queryGroup.stars?.filter((star) => star.interest_id !== interest),
    });
  };

  const handleLimiterChange = (limiter: number) =>
    updateQueryGroup({ limiter });

  const handleClearInterests = () => updateQueryGroup({ interests: [] });

  const handleQueryModeChange = (query_mode: string) => {
    // when query mode is change, stars should be cleared for STAR mode and query should be cleared for INTEREST mode
    if (query_mode === QueryMode.INTEREST) {
      updateQueryGroup({ stars: [], query_mode });
    } else if (query_mode === QueryMode.STAR) {
      setQueryGroup((prevState) => ({
        ...prevState,
        query_mode,
        query: prevState.interests.map((interest) => ({
          operator: "ANY",
          type_filter: [],
          limiter: 1,
          interest_id: interest,
        })),
      }));
    }
    updateQueryGroup({ query_mode });
  };

  const handleClearStars = () => updateQueryGroup({ stars: [] });

  const handleAllStarChange = (starChanges: IStar[]) => {
    let updatedStars;

    if (
      starChanges.every((star) =>
        queryGroup.stars?.map((s) => s.id).includes(star.id)
      )
    ) {
      // If all stars are already selected, remove them (deselect)
      updatedStars = queryGroup.stars?.filter(
        (star) => !starChanges.map((s) => s.id).includes(star.id)
      );
    } else {
      // Add new stars, making sure there are no duplicates
      if (!queryGroup.stars) return;
      updatedStars = [...new Set([...queryGroup.stars, ...starChanges])];
    }

    updateQueryGroup({ stars: updatedStars });
  };

  const handleStarChange = (star: IStar) => {
    if (!queryGroup.stars) return;
    updateQueryGroup({ stars: [...queryGroup.stars, star] });
  };

  const handleStarRemove = (star: IStar) => {
    if (!queryGroup.stars) return;
    updateQueryGroup({
      stars: queryGroup.stars.filter((s) => s.id !== star.id),
    });
  };

  const handleOperatorChange = (operator: string) =>
    updateQueryGroup({ operator });

  const handleQueryOperatorChange = (queryIndex: number, operator: string) => {
    updateQueryGroup({
      query: queryGroup.query.map((q, index) => {
        if (index === queryIndex) return { ...q, operator };
        return q;
      }),
    });
  };

  const handleQueryLimiterChange = (queryIndex: number, limiter: number) => {
    updateQueryGroup({
      query: queryGroup.query.map((q, index) => {
        if (index === queryIndex) return { ...q, limiter };
        return q;
      }),
    });
  };

  const handleQueryTypeChange = (queryIndex: number, starTypes: number[]) => {
    updateQueryGroup({
      query: queryGroup.query.map((q, index) => {
        if (index === queryIndex) return { ...q, type_filter: starTypes };
        return q;
      }),
    });
  };

  const handleSummaryChange = useCallback((summary: string) => {
    updateQueryGroup({ summary });
  }, []);

  const steps: StepProps[] = [
    {
      title: "Choose an Industry",
      component: (
        <IndustrySection
          industry={queryGroup?.industry}
          onIndustryChange={handleIndustryChange}
        />
      ),
    },
    {
      title: "Choose your Interests",
      component: (
        <InterestSelection
          interests={queryGroup.interests}
          industry={queryGroup?.industry}
          onClearInterests={handleClearInterests}
          onInterestChange={handleInterestChange}
          onInterestDelete={handleInterestDelete}
        />
      ),
    },
    {
      title: "Configure your Query",
      component: (
        <ConfigureQuerySection
          queryMode={queryGroup.query_mode}
          interests={queryGroup.interests}
          country={audience.country}
          query={queryGroup.query}
          limiter={queryGroup?.limiter}
          selectedStars={queryGroup.stars}
          operator={queryGroup.operator}
          onStarChange={handleStarChange}
          onAllStarChange={handleAllStarChange}
          onStarRemove={handleStarRemove}
          onClearStars={handleClearStars}
          onLimiterChange={handleLimiterChange}
          onInterestChange={handleInterestChange}
          onIndustryChange={handleIndustryChange}
          onOperatorChange={handleOperatorChange}
          onQueryOperatorChange={handleQueryOperatorChange}
          onQueryModeChange={handleQueryModeChange}
          onQueryTypeChange={handleQueryTypeChange}
          onQueryLimiterChange={handleQueryLimiterChange}
        />
      ),
    },
    {
      title: "Query Group Summary",
      component: (
        <QueryGroupSummary
          queryGroup={queryGroup}
          country={audience.country}
          onSummaryChange={handleSummaryChange}
          onEdit={(step) => setActiveStep(step)}
        />
      ),
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === steps.length - 1) {
      onConfirm(queryGroup);
      onClose();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleOnClose = () => {
    setOpenConfirmationDialog(true);
    history.push("/");
  };

  const onUnsavedChangesConfirm = () => {
    setOpenConfirmationDialog(false);
    setQueryGroup(defaultQueryGroup);
    onClose();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleOnClose}
        sx={{ minWidth: '900px'}}
        PaperProps={{ sx: { height: QUERY_GROUP_STEP_FORM_HEIGHT } }}
      >
        <DialogTitle key="title">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              width={"100%"}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel sx={{ width: 120 }}>{step.title}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                m: 1,
              }}
            >
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleOnClose}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <Divider sx={{ mb: 2 }} />
        <DialogContent>{steps[activeStep].component}</DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Button
            variant={"outlined"}
            color="secondary"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={isNextDisabled}
            onClick={handleNext}
          >
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </DialogActions>
      </Dialog>
      {openConfirmationDialog && (
        <UnsavedChangesDialog
          open={openConfirmationDialog}
          onClose={() => setOpenConfirmationDialog(false)}
          onConfirm={onUnsavedChangesConfirm}
        />
      )}
    </>
  );
};
