import { createTheme } from "@mui/material/styles";

import { ThemeProvider } from "@mui/system";
import React, { PropsWithChildren } from "react";

interface ThemeContainerProps {
  children: React.ReactNode;
}

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#4ed4eb",
      light: "#82e1f2",
      dark: "#00b1cc",
      contrastText: "#212b3a",
    },
    secondary: {
      main: "#00b1cc",
      light: "#4ed4eb",
      dark: "#00656d",
      contrastText: "#212b3a",
    },
    info: {
      main: "#f5b49f",
      light: "#f7ebea",
      dark: "#ea673b",
      contrastText: "#212b3a",
    },
    warning: {
      main: "#ab9ec9",
      light: "#ebe7f2",
      dark: "#543b94",
      contrastText: "#212b3a",
    },
    error: {
      main: "#F3546D",
      light: "#ffe8ee",
      dark: "#ff0047",
      contrastText: "#212b3a",
    },
    background: {
      default: "#212b3a",
      paper: "#0B1627",
    },
  },
  typography: {
    fontFamily: "cera-pro",
  },
  components: {
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "4px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
        },
      },
    },
  },
});

export const ThemeContainer = ({
  children,
}: PropsWithChildren<ThemeContainerProps>): JSX.Element | null => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
