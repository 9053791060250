import {QUERY_GROUP_STEP_HEIGHT} from "@/constants";
import {Box, Card, CardContent, Divider, Skeleton, Typography} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

import React, {FC} from "react";

const SKELETON_COUNT = 15;

export const IndustrySectionSkeleton: FC = () => {
	return (
		<Grid container spacing={2} sx={{height: QUERY_GROUP_STEP_HEIGHT}}>
			<Grid xs={12}>
				<Card sx={{height: '100px'}}>
					<CardContent sx={{height: '100%', textAlign: 'center'}}>
						<Box sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100%'
						}}>
							<Typography variant="h6">
								<Skeleton variant="text" width={100}/>
							</Typography>
							<Typography variant="body2">
								<Skeleton variant="text" width={200}/>
							</Typography>
						</Box>
					</CardContent>
				</Card>
				<Divider sx={{mt: 2}}/>
			</Grid>
			{Array.from({length: SKELETON_COUNT}).map((_, index) => (
				<Grid xs={12} sm={6} md={4} lg={3} key={index}>
					<Card sx={{height: '140px'}}>
						<CardContent sx={{height: '100%'}}>
							<Box display="flex" flexDirection="row">
								<Skeleton variant="circular" width={30} height={30} sx={{mr: 2}}/>
								<Typography variant="h6">
									<Skeleton variant="text" width={100}/>
								</Typography>
							</Box>
							<Typography variant="body2">
								<Skeleton variant="text" width="95%"/>
								<Skeleton variant="text" width="75%"/>
								<Skeleton variant="text" width="65%"/>
							</Typography>
						</CardContent>
					</Card>
				</Grid>
			))}
		</Grid>
	);
};
