import {
  StarFollowersSlider,
  StarOperatorDropdown,
  StarSearch,
  StarTypeSearch,
} from "@/components";
import { QueryOperator } from "@/enums";
import { IList } from "@/interfaces";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { ChangeEvent, FC } from "react";

interface FilterProps {
  interests: number[];
  country: number;
  operator: string;
  limiter: number;
  onStarSearchChange: (searchText: string) => void;
  onOperatorChange: (operator: string) => void;
  onStarTypeSearchChange: (types: IList[]) => void;
  onLimiterChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onStarFollowersChange: (bounds: number[]) => void;
}

export const Filters: FC<FilterProps> = ({
  interests,
  country,
  operator,
  limiter,
  onStarSearchChange,
  onStarFollowersChange,
  onOperatorChange,
  onLimiterChange,
  onStarTypeSearchChange,
}) => {
  return (
    <Grid container xs={12}>
      <Grid container xs={12} spacing={2}>
        <Grid xs={2.2}>
          <StarSearch onStarSearchChange={onStarSearchChange} />
        </Grid>

        <Grid xs={3}>
          <StarTypeSearch
            onStarTypeChange={onStarTypeSearchChange}
            interests={interests}
            country={country}
          />
        </Grid>

        <Grid xs={3}>
          <StarOperatorDropdown
            operator={operator}
            onOperatorChange={onOperatorChange}
          />
        </Grid>

        <Grid xs={0.8}>
          <TextField
            size="small"
            fullWidth
            disabled={
              operator === QueryOperator.ANY || operator === QueryOperator.ALL
            }
            label="Limiter"
            type="number"
            variant="filled"
            value={limiter}
            onChange={onLimiterChange}
            InputLabelProps={{ shrink: !!operator }}
          />
        </Grid>

        <Grid xs={3}>
          <StarFollowersSlider onFollowersChange={onStarFollowersChange} />
        </Grid>
      </Grid>
    </Grid>
  );
};
