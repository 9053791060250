import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";

interface EditQueryGroupWarningDialogProps {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

export const EditQueryGroupWarningDialog: React.FC<EditQueryGroupWarningDialogProps> = ({open, onClose, onConfirm}) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
		>
			<DialogTitle>
				Editing query group
			</DialogTitle>
			<DialogContent>
				<Typography>
					Warning: Editing this query group will impact your query group selection.
				</Typography>
				<Typography mt={2}>
					Are you sure you wish to close?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>
					Cancel
				</Button>
				<Button variant="contained" onClick={onConfirm}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	)
}
