import {useStarTypesByInterestList} from "@/hooks/useStarTypesByInterestList";
import {IList, InterestQuery} from "@/interfaces";
import {Box, Button, Paper, Typography} from "@mui/material";
import React, {useMemo} from "react";

interface QuerySummaryProps {
  interests: IList[];
  country: number;
  query: InterestQuery[];
  operator: string;
  paper?: boolean; // enable paper styling to make it reusable
  onEditQuery?: () => void;
  editMode?: boolean;
}

export const QuerySummary: React.FC<QuerySummaryProps> = ({
  interests,
  country,
  query,
  operator,
  paper,
  onEditQuery,
  editMode,
}) => {
  const interestsList = useMemo(
    () => interests.map((interest) => interest.id),
    [interests]
  );
  const { starTypesList } = useStarTypesByInterestList(country, interestsList);

  const renderInterests = useMemo(() => {
    return interests.map((interest, index) => {
      const interestQuery = query.find(
        (queryItem) => queryItem.interest_id === interest.id
      );

      const selectedStarTypes = starTypesList
        .filter((type) => interestQuery?.type_filter?.includes(type.id))
        .map((type) => type.name);

      return (
        <React.Fragment key={interest.id}>
          <Typography variant="body1" color="textSecondary" ml={0.5}>
            {interestQuery?.operator.toLowerCase()}{" "}
            {interestQuery?.operator.toLowerCase() !== "any"
              ? Math.max(interestQuery?.limiter || 0, 1)
              : ""}
          </Typography>
          <Typography variant="body1" color="primary.main" ml={0.5}>
            {interest.name}
          </Typography>
          {selectedStarTypes.length > 0 ? (
            <Typography variant="body1" color="primary.light" ml={0.5}>
              {selectedStarTypes.length === 1
                ? selectedStarTypes.slice(0, -1).join(", ") +
                  selectedStarTypes.slice(-1)
                : selectedStarTypes.slice(0, -1).join(", ") +
                  " or " +
                  selectedStarTypes.slice(-1)}
            </Typography>
          ) : (
            <Typography variant="body1" ml={0.5}>
              {interestQuery?.limiter && interestQuery?.limiter > 1
                ? "accounts"
                : "account"}
            </Typography>
          )}
          {index < (query?.length || 0) - 1 && <Typography>,</Typography>}
          <Typography variant="body1" ml={0.5}>
            {index < (query?.length || 0) - 1
              ? `${operator?.toLowerCase()} followers of `
              : ""}
          </Typography>
        </React.Fragment>
      );
    });
  }, [interests, query, starTypesList, operator]);

  return paper ? (
    <Paper
      elevation={2}
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "50px",
        width: "100%",
      }}
    >
      <Box display="flex" flexWrap="wrap" alignItems="center">
        <Typography variant="body1">Followers of</Typography>
        {renderInterests}
      </Box>

      {onEditQuery && (
        <Button size="small" variant="outlined" onClick={onEditQuery}>
          {editMode ? "Close" : "Edit"}
        </Button>
      )}
    </Paper>
  ) : (
    <Box display="flex" flexWrap="wrap" alignItems="center">
      <Typography variant="body1">Followers of</Typography>
      {renderInterests}
    </Box>
  );
};
