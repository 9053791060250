import { InsightModeIndicator } from "@/components";
import { abbreviateNumber } from "@/helpers";
import { IAudience } from "@/interfaces";
import { reformatDate } from "@/utils";
import {
  BarChartOutlined,
  Delete,
  MoreVert,
  PeopleAlt,
  PieChart,
  PlayArrow,
  RestartAlt,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { FC, useState } from "react";

interface AudienceCardProps {
  audience: IAudience;
  isBuilding: false | null | boolean;
  onEdit: (id: number | undefined) => void;
  onBuildAudience: (audience: IAudience) => void;
  onRebuildAudience: (audience: IAudience) => void;
  onSelectedAudience: (audience: IAudience) => void;
  onShowDeleteDialog: (open: boolean) => void;
  onShowInsight: (id: number | undefined) => void;
}

export const AudienceCard: FC<AudienceCardProps> = ({
  audience,
  isBuilding,
  onSelectedAudience,
  onEdit,
  onShowDeleteDialog,
  onBuildAudience,
  onRebuildAudience,
  onShowInsight,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleShowInsightMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleInsightClose = () => setAnchorEl(null);

  const handleDeleteAudience = () => {
    onShowDeleteDialog(true);
    onSelectedAudience(audience);
  };

  const handleRebuildAudience = () => onRebuildAudience(audience);

  const renderInsightButton = (audience: IAudience) => {
    if (audience.run_id) {
      return isBuilding ? (
        <Tooltip title={"Building insight"}>
          <IconButton sx={{ p: 0.5, borderRadius: "6px" }}>
            <CircularProgress size={18} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={"Insight actions"}>
          <>
            <IconButton
              sx={{ p: 0, borderRadius: "6px" }}
              onClick={handleShowInsightMenu}
            >
              <MoreVert color="primary" />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleInsightClose}>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  onShowInsight(audience?.id);
                }}
              >
                <BarChartOutlined color="primary" sx={{ mr: 1 }} />
                View Insight
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  onBuildAudience(audience);
                }}
              >
                <RestartAlt color="primary" sx={{ mr: 1 }} />
                Rebuild Insight
              </MenuItem>
            </Menu>
          </>
        </Tooltip>
      );
    } else {
      return isBuilding ? (
        <Tooltip title={"Building insight"}>
          <IconButton sx={{ p: 0.5 }}>
            <CircularProgress size={18} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={"Build insight"}>
          <IconButton
            sx={{ p: 0, borderRadius: "6px" }}
            onClick={() => onBuildAudience(audience)}
          >
            <PlayArrow color="primary" />
          </IconButton>
        </Tooltip>
      );
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "250px",
        height: "200px",
      }}
    >
      <CardActionArea onClick={() => onEdit(audience?.id)}>
        <CardHeader
          avatar={<Chip label={audience.country_iso} size="small" />}
          action={
            <InsightModeIndicator
              id={audience.run_id}
              date={audience.run_date}
            />
          }
          sx={{ p: 1, pb: 0 }}
        />
        <CardContent
          sx={{
            height: 126,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            textAlign="center"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {audience.name}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-evenly"
            width="100%"
          >
            {audience.modelled_count && (
              <Box display="flex" flexDirection="row" alignItems="center">
                <PeopleAlt sx={{ mr: 1 }} />
                <Typography>
                  {abbreviateNumber(audience.modelled_count)}
                </Typography>
              </Box>
            )}
            {audience.segment_run_id && audience.segment_run_date && (
              <Box display="flex" flexDirection="row" alignItems="center">
                <PieChart sx={{ mr: 1 }} />
                <Typography>{audience.segment_count}</Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pl: 2,
        }}
      >
        <Box>
          {audience.run_date && (
            <Typography
              component="div"
              variant="body2"
              color={"text.secondary"}
              mt={1}
            >
              {reformatDate(audience.run_date)}
            </Typography>
          )}
        </Box>
        <Box>
          <Tooltip title={"Rebuild Audience"}>
            <IconButton
              onClick={handleRebuildAudience}
              sx={{ p: 0.5, borderRadius: "6px" }}
            >
              <RestartAlt color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Delete Audience"}>
            <IconButton
              onClick={handleDeleteAudience}
              sx={{ p: 0.5, borderRadius: "6px" }}
            >
              <Delete color="primary" />
            </IconButton>
          </Tooltip>
          {renderInsightButton(audience)}
        </Box>
      </CardActions>
    </Card>
  );
};
