import {InterestQuerySectionSkeleton, QueryBlock, QuerySummary,} from "@/components";
import {useInterestList} from "@/hooks/useInterestList";
import {InterestQuery} from "@/interfaces";
import {SelectChangeEvent} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, {ChangeEvent, useCallback} from "react";

import {Filters} from "./filters";

const defaultQueryItem: InterestQuery = {
  operator: "ANY",
  limiter: 1,
  interest_id: null,
  type_filter: [],
};

interface InterestQuerySectionProps {
  interests: number[];
  country: number;
  query: InterestQuery[];
  operator: string;
  onOperatorChange: (operator: string) => void;
  onQueryOperatorChange: (queryIndex: number, operator: string) => void;
  onQueryTypeChange: (queryIndex: number, starTypes: number[]) => void;
  onQueryLimiterChange: (queryIndex: number, limiter: number) => void;
}

export const InterestQuerySection: React.FC<InterestQuerySectionProps> = ({
  interests,
  country,
  query,
  operator,
  onOperatorChange,
  onQueryOperatorChange,
  onQueryLimiterChange,
  onQueryTypeChange,
}) => {
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const { interestList, loading } = useInterestList();
  const interestsList = interests.map(
    (interest) => interestList.filter((item) => item.id === interest)[0]
  );

  const handleQueryStarTypeChange = useCallback(
    (queryIndex: number, starTypes: number[]) => {
      onQueryTypeChange(queryIndex, starTypes);
    },
    [onQueryTypeChange]
  );

  const handleQueryLimiterChange = useCallback(
    (queryIndex: number, limiter: number) => {
      onQueryLimiterChange(queryIndex, limiter);
    },
    [onQueryLimiterChange]
  );

  const handleQueryOperatorChange = useCallback(
    (queryIndex: number, operator: string) => {
      onQueryOperatorChange(queryIndex, operator);
    },
    [onQueryOperatorChange]
  );

  if (loading) return <InterestQuerySectionSkeleton />;

  return (
    <Grid container xs={12} spacing={2}>
      <Grid xs={12}>
        <QuerySummary
          paper
          interests={interestsList}
          country={country}
          query={query}
          operator={operator}
          onEditQuery={() => setEditMode(!editMode)}
          editMode={editMode}
        />
      </Grid>
      {editMode && (
        <>
          <Grid xs={3}>
            <Filters operator={operator} onOperatorChange={onOperatorChange} />
          </Grid>
          <Grid xs={12} px={0}>
            {query.map((_interestQuery, index) => {
              return (
                <QueryBlock
                  key={index}
                  interest={interestsList[index]}
                  country={country}
                  queryItem={query[index] || defaultQueryItem}
                  onQueryStarTypeChange={(type) =>
                    handleQueryStarTypeChange(index, type)
                  }
                  onQueryOperatorChange={(event: SelectChangeEvent) =>
                    handleQueryOperatorChange(index, event.target.value)
                  }
                  onQueryLimiterChange={(
                    event: ChangeEvent<HTMLInputElement>
                  ) =>
                    handleQueryLimiterChange(index, Number(event.target.value))
                  }
                />
              );
            })}
          </Grid>
        </>
      )}
    </Grid>
  );
};
