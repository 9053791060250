import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";

import { formatPercentage } from "@/utils";

type SegmentSizeChartProps = {
  size: number;
  color: string;
};

const chartSize = 60;

export const SegmentSizeChart: React.FC<SegmentSizeChartProps> = ({
  size,
  color,
}) => {
  return (
    <Stack gap={1} alignItems="center">
      <Typography variant="caption" color={"text.secondary"}>
        Segment Size
      </Typography>
      <Box
        sx={{
          height: {chartSize},
          width: {chartSize},
          position: "relative",
          display: "inline-flex",
        }}
      >
        {/* Background circle acting as the border */}
        <CircularProgress
          variant="determinate"
          value={100}
          sx={{
            color: "rgba(0, 0, 0, 0.1)",
          }}
          thickness={4}
          size={chartSize}
        />
        {/* Actual progress circle */}
        <CircularProgress
          variant="determinate"
          value={size}
          sx={{
            color: { color },
            position: "absolute",
            top: 0,
            left: 0,
          }}
          thickness={4}
          size={chartSize}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {formatPercentage(size)}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};
