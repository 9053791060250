import { fetchData } from "@/api/utils";
import { AxiosRequestConfig } from "axios";

export const getTypes = async () => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/list/types`,
  };

  return fetchData(config);
};

export const getTypesByInterest = async (
  country: number,
  interests?: number[]
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/list/types`);
  const params = new URLSearchParams();
  params.append("country", country.toString());

  if (interests?.length) {
    params.append("interests", interests.join(","));
  }

  url.search = params.toString();

  const config: AxiosRequestConfig = {
    method: "GET",
    url: url.toString(),
  };

  return fetchData(config);
};
