import { IAudience, IAudienceSegment, ICountry } from "@/interfaces";
import { sortBy } from "lodash";
import { InsightMode } from "@/enums";
import {INSIGHT_MODE_LIMIT_DAYS, segmentColors} from "@/constants";

export const fullScreen = async () => {
  if (!document.fullscreenElement) {
    try {
      await document.documentElement.requestFullscreen();
    } catch (err) {
      console.error(err);
    }
  } else {
    if (document.exitFullscreen) {
      try {
        await document.exitFullscreen();
      } catch (err) {
        console.error(err);
      }
    }
  }
};

export const formatFollowersCount = (followers: number) => {
  if (followers >= 1000000) {
    return `${(followers / 1000000).toFixed(1)}M`;
  } else if (followers >= 1000) {
    return `${(followers / 1000).toFixed(1)}K`;
  } else {
    return followers.toString();
  }
};

export const formatPercentage = (value: number) => {
  if (value >= 1) {
    return `${value.toFixed(1)}%`;
  } else {
    return `${(value * 100).toFixed(1)}%`;
  }
};

export const reformatDate = (date: string | undefined) => {
  const dateObj = new Date(date || "");
  const day = dateObj.getDate();
  const month = dateObj.toLocaleString("default", { month: "short" });
  const year = dateObj.getFullYear();
  return `${day} ${month} ${year}`;
};

export const isInsightOutdated = (
  date: string | undefined,
  daysLimit: number
) => {
  const currentDate = new Date();
  const insightDate = new Date(date || "");
  const timeDifference = currentDate.getTime() - insightDate.getTime();
  const daysDifference = Math.round(timeDifference / (1000 * 3600 * 24));
  return daysDifference > daysLimit;
};

export const determineMode = (
  id: number | undefined,
  date: string | undefined
) => {
  if (!id || !date) {
    return InsightMode.NEEDS_TO_RUN;
  }
  if (isInsightOutdated(date, INSIGHT_MODE_LIMIT_DAYS)) {
    return InsightMode.STALE;
  }
  return InsightMode.READY;
};

export const sortAudiences = (
  audiences: IAudience[],
  type: string | null | undefined
) => {
  if (type === "date") {
    return sortBy(
      audiences.map((x) =>
        Object.assign({}, x, {
          dt: new Date(x.run_date || "1970-01-01").getTime(),
        })
      ),
      "dt"
    ).reverse();
  } else if (type === "name") {
    return sortBy(audiences, "name");
  } else if (type === "size") {
    return sortBy(
      audiences.map((x) =>
        Object.assign({}, x, { size: x.modelled_count || 0 })
      ),
      "size"
    ).reverse();
  } else {
    return sortBy(audiences, "id").reverse();
  }
};

export const filterAudiences = (
  audiences: IAudience[],
  searchText: string,
  filters: InsightMode[],
  country: ICountry | undefined
) => {
  return audiences
    .filter((x) =>
      filters.length > 0 ? filters.includes(determineMode(x.id, x.run_date)) : x
    )
    .filter((x) =>
      searchText !== ""
        ? x.name.toLowerCase().includes(searchText.toLowerCase())
        : x
    )
    .filter((x) => (country ? x.country === country.id : x));
};

export const extractCountries = (audiences: IAudience[]) => {
  const countries: ICountry[] = audiences
    .filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.country_iso === value.country_iso)
    )
    .map((audience) => ({
      id: audience.country,
      iso: audience.country_iso,
      name: audience.country_name,
    }));
  return sortBy(countries, "id");
};

export const createSegmentColor = (
  segments: IAudienceSegment[]
): Record<string, string> => {
  return segments.reduce((colorMap, segment, index) => {
    colorMap[segment.id] = segmentColors[index % segmentColors.length]
    return colorMap;
  }, {} as Record<string, string>);
};
