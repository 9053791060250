import {Box, Stack, Typography} from "@mui/material";
import React from "react";

interface RatioChartProps {
  title: string;
  labels: string[],
  values: number[],
  baseline: number[]
}

function formatPercentage(value: number) {
  return `${(value * 100).toFixed(1)}%`
}

function toLabel(value: string) {
  switch (value) {
    case 'male':
      return 'Male'
    case 'female':
      return 'Female'
    default:
      return 'Male'
  }
}

export const RatioChart: React.FC<RatioChartProps> = ({title, labels, values, baseline}) => {

  const value = values[0] > values[1] ? values[0] : + values[1];

  const valueLabel = values[0] > values[1] ? 'Male' : 'Female'
  const baseLabel = baseline[0] > baseline[1] ? 'Male' : 'Female'

  return (
    <Box>
      <Typography variant="h6" mb={2}>{title}</Typography>
      <Box height={250}>
        <Stack spacing={3}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            {labels.map((label) => <Typography key={label}>{toLabel(label)}</Typography>)}
          </Box>
          <Box>
            <Typography variant="body2" sx={{translate: `${(values[0] * 80)}%`}}>{`${formatPercentage(value)} ${valueLabel}`}</Typography>
            <Box position="relative" mx="10%" my={0.5}>
              <Box  sx={{ height: 10, width: "100%", borderRadius: 5, background: "#49505B" }}/>
              <Box  sx={{ height: 10, width: values[0], borderRadius: '5px 0px 0px 5px', background: "#00B0CC", position: "absolute", left: 0, top: 0 }}/>
              <Box  sx={{ height: 12, width: 2, background: "#FFF", position: "absolute", left: `${values[0] * 100}%`, top: -1 }}/>
            </Box>
            <Typography variant="body2" textAlign="center">Audience</Typography>
          </Box>
          <Box>
            <Typography variant="body2" sx={{translate: `${(baseline[0] * 80)}%`}}>{`${formatPercentage(baseline[0])} ${baseLabel}`}</Typography>
            <Box position="relative" mx="10%" my={0.5}>
              <Box  sx={{ height: 10, width: "100%", borderRadius: 5, background: "#49505B" }}/>
              <Box  sx={{ height: 10, width: baseline[0], borderRadius: '5px 0px 0px 5px', background: "#B1BAC6", position: "absolute", left: 0, top: 0 }}/>
              <Box  sx={{ height: 12, width: 2, background: "#FFF", position: "absolute", left: `${baseline[0] * 100}%`, top: -1 }}/>
            </Box>
            <Typography variant="body2" textAlign="center">Baseline</Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
