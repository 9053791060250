import { ProgressStep } from "@/enums";
import { CheckCircle, Close, Error } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Modal,
  Typography,
} from "@mui/material";
import React, {FC, memo, useEffect, useState} from "react";

interface ProgressModalProps {
  open: boolean;
  onClose: () => void;
  progressStep: ProgressStep;
  title: string;
  description?: string;
  steps: Array<Step>;
}

export type Step = {
  step: ProgressStep;
  description: string;
  hasError?: boolean;
  isCompleted?: boolean;
};

const calculateProgress = (
  progressStep: ProgressStep,
  steps: Step[]
): number => {
  const progressSteps = steps.map((step) => step.step);
  const currentStepIndex = progressSteps.indexOf(progressStep);
  return (currentStepIndex / steps.length) * 100;
};

const StepStatus: FC<{ step: Step; currentStep: ProgressStep }> = memo(
  ({ step, currentStep }) => {
    const isErrorForThisStep = step.hasError;
    const isStepCompleted = step.isCompleted;
    const isActiveStep = currentStep === step.step;

    if (isErrorForThisStep) return <Error color="error" fontSize="small" />;
    if (isStepCompleted)
      return <CheckCircle color="success" fontSize="small" />;
    if (isActiveStep) return <CircularProgress size={20} />;
    return <span style={{ width: 20, height: 20 }} />;
  }
);

export const ProgressModal: FC<ProgressModalProps> = ({
  onClose,
  open,
  progressStep,
  title,
  description,
  steps,
}) => {
  const [progress, setProgress] = useState(0);
  const isError = steps.find((step) => step.step === progressStep)?.hasError;

  useEffect(() => {
    setProgress(calculateProgress(progressStep, steps));
  }, [progressStep, steps]);

  useEffect(() => {
    if (progressStep === ProgressStep.Completed) {
      setTimeout(onClose, 1000);
    }
  }, [progressStep, onClose]);
  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          p: 3,
          bgcolor: "background.paper",
          borderRadius: 2,
          position: "relative",
        }}
      >
        {isError && (
          <IconButton
            color="primary"
            onClick={onClose}
            size="small"
            sx={{
              position: "absolute",
              top: "-15px",
              right: "-10px",
              backgroundColor: "background.paper",
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        )}
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ width: "100%" }}
        />
        <Typography variant="h4" sx={{ mt: 2 }}>
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {steps.map((step, index) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: 1,
                }}
                key={index}
              >
                <StepStatus step={step} currentStep={progressStep} />
                <Typography variant="body1">{step.description}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        {isError && (
          <Typography variant="body2" color="error" textAlign="center">
            Something went wrong, please try again.
            <br />
            If the problem persists, please contact your account manager
          </Typography>
        )}
      </Box>
    </Modal>
  );
};
