import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";

interface UnsavedChangesDialogProps {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

export const UnsavedChangesDialog: React.FC<UnsavedChangesDialogProps> = ({open, onClose, onConfirm}) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
		>
			<DialogTitle>
				You have unsaved changes!
			</DialogTitle>
			<DialogContent>
				<Typography>
					If you close this dialog, you will lose any unsaved changes you have made to this audience.
				</Typography>
				<Typography mt={2}>
					Are you sure you wish to close?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>
					Don't Close
				</Button>
				<Button variant="contained" onClick={onConfirm}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	)
}
