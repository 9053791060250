import React, { FC, useState, useEffect } from "react";
import { sortBy } from "lodash";

import { Stack, Typography, SvgIcon } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { InsightCard, Tooltip } from "@/components";
import { ITagScore } from "@/interfaces";
import { interestIcon } from "@/constants/icon-map";
import { useTheme } from "@mui/material/styles";

interface InsightCardInterestProps {
  title: string;
  interests: ITagScore[];
}

function groupBy(list: ITagScore[], keyGetter: (x: ITagScore) => string) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const InsightCardInterest: FC<InsightCardInterestProps> = ({
  title,
  interests,
}) => {
  const theme = useTheme();
  const color = theme.palette.augmentColor({
    color: { main: theme.palette.background.paper },
  });

  const groupMap = groupBy(
    interests.slice(0, 30).filter((x) => x.group !== x.name),
    (interest: ITagScore) => interest.group
  );
  const groupScores = Array.from(groupMap.values()).map((interests, idx) => ({
    name: Array.from(groupMap.keys())[idx],
    index:
      interests.reduce(
        (total: number, next: ITagScore) => total + next.index,
        0
      ) / interests.length,
    size: interests.reduce((total: number) => total + 1, 0),
  }));
  const groups = sortBy(
    groupScores.filter((x) => x.size >= 2),
    "index"
  )
    .reverse()
    .slice(0, 3);

  const [selectedGroup, setSelectedGroup] = useState<string>(groups[0].name);

  useEffect(() => {
    setSelectedGroup(groups[0].name);
  }, [interests]);

  const subInterests = sortBy(
    interests.filter(
      (interest) =>
        interest.group === selectedGroup && interest.name !== selectedGroup
    ),
    "index"
  )
    .reverse()
    .slice(0, 3);

  const topInterests = [...interests].sort((a, b) => b.index - a.index).slice(0, 6)

  const handleGroupSelect = (interest: string) => {
    setSelectedGroup(interest);
  };

  const calculateInterestRadius = (
    groupIdx: number,
    interestIdx: number,
    lastIdx: number
  ) => {
    if (interestIdx === 0) {
      return "6px 0px 0px 6px";
    } else if (interestIdx === lastIdx - 1) {
      return "0px 6px 6px 0px";
    } else {
      return "0px 0px 0px 0px";
    }
  };

  const calculateInterestJustify = (groupIdx: number, numInterests: number) => {
    if (numInterests < 3) {
      if (groupIdx === 0) {
        return "flex-start";
      } else if (groupIdx === 2) {
        return "flex-end";
      } else {
        return "center";
      }
    } else {
      return "center";
    }
  };

  return (
    <InsightCard title={title}>
      <Grid container spacing={3} mx={1} justifyContent="center">
        {topInterests.map((interest, idx) => {
          return (
            <Grid
              xs={4}
              key={interest.name}
            >
              <Tooltip placement="bottom" title={interest.name}>
                <Stack spacing={1} alignItems="center">
                  <SvgIcon
                    component={interestIcon(interest.name).ReactComponent}
                    inheritViewBox
                    sx={{ fontSize: "30px" }}
                  />
                  <Typography
                    variant="caption"
                    align="center"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {interest.name.split(" / ")[0]}
                  </Typography>
                </Stack>
              </Tooltip>
            </Grid>
          );
        })}
      </Grid>
    </InsightCard>
  );
};
