import {
  BarChart,
  Metric,
  RadialChart,
  RatioChart,
  StarTable,
  TagTable,
} from "@/components";
import { IAudienceInsight, IAudienceSegment, IScore } from "@/interfaces";
import { formatPercentage } from "@/utils";
import {
  Alert,
  AlertTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

type DetailedViewProps = {
  insight: IAudienceInsight;
  selectedSegment: IAudienceSegment;
  onSegmentChange: (newValue: number) => void;
  error?: string | null;
};

export const DetailedView: React.FC<DetailedViewProps> = ({
  insight,
  selectedSegment,
  onSegmentChange,
  error,
}) => {
  const { segments } = insight;
  const theme = useTheme();
  const [demographics, setDemographics] = useState<IScore[]>([]);

  useEffect(() => {
    setDemographics(selectedSegment.summary.demographics);
  }, [selectedSegment]);

  return (
    <Grid xs={12} container spacing={4} px={2} justifyContent="center" alignItems="center">
      {error ? (
        <Grid xs={12}>
          <Alert
            severity="error"
            sx={{ background: theme.palette.background.paper }}
          >
            <AlertTitle>No Insights Found</AlertTitle>
            No Insights found for this audience. Please check that the audience
            has been built successfully and that audience insights are ready to
            view.
          </Alert>
        </Grid>
      ) : (
        insight && (
          <>
            <Grid xs={12}>
              <Stack direction="row" alignItems="center" spacing={6}>
                <Typography variant="h4">{insight.audience_name}</Typography>
                <Metric
                  label="Size"
                  value={selectedSegment.summary.audience_size.social_size}
                />
                <Metric
                  label="Modelled"
                  value={selectedSegment.summary.audience_size.modelled_size}
                />
              </Stack>
            </Grid>
            {segments && segments.length > 0 && (
              <Grid xs={12} py={2}>
                <FormControl variant="filled" size="small">
                  <InputLabel id="view-select-label">Segment</InputLabel>
                  <Select
                    value={selectedSegment.id}
                    onChange={(event) =>
                      onSegmentChange(event.target.value as number)
                    }
                    label="Segments"
                  >
                    {segments.map((segment, index) => (
                      <MenuItem key={segment.id} value={segment.id}>
                        {index === 0
                          ? segment.name
                          : `${segment.name} (${formatPercentage(segment.summary.audience_size.segment_percent)})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid xs={12} container spacing={6} mb={4}>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <BarChart
                  title={"Age"}
                  labels={demographics
                    .filter((x) => x.type === "Age")
                    .map((x) => x.name)}
                  values={demographics
                    .filter((x) => x.type === "Age")
                    .map((x) => x.penetration)}
                  baseline={demographics
                    .filter((x) => x.type === "Age")
                    .map((x) => (x.baseline ? x.baseline : 0))}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <RatioChart
                  title={"Gender"}
                  labels={demographics
                    .filter((x) => x.type === "Gender")
                    .map((x) => x.name)}
                  values={demographics
                    .filter((x) => x.type === "Gender")
                    .map((x) => x.penetration)}
                  baseline={demographics
                    .filter((x) => x.type === "Gender")
                    .map((x) => (x.baseline ? x.baseline : 0))}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <RadialChart
                  title={"Media Channel Preference"}
                  labels={(selectedSegment.summary.channels).map((x) => x.name)}
                  values={(selectedSegment.summary.channels).map((x) => x.index)}
                />
              </Grid>
            </Grid>
            <Grid xs={12} md={6} mb={2}>
              <StarTable
                title="Top Influencers"
                rows={selectedSegment.score.influencers}
              />
            </Grid>
            <Grid xs={12} md={6} mb={2}>
              <TagTable
                title="Top Interests"
                rows={selectedSegment.score.interests}
              />
            </Grid>
            <Grid xs={12} md={6} mb={2}>
              <StarTable
                title="Top Brands"
                rows={selectedSegment.score.brands}
              />
            </Grid>
            <Grid xs={12} md={6} mb={2}>
              <StarTable
                title="Top Media"
                rows={selectedSegment.score.media}
              />
            </Grid>
          </>
        )
      )}
    </Grid>
  );
};
