import {AuthContainer} from "@/components/containers";
import {ThemeContainer} from "@/components/containers/theme-container";
import {StyledEngineProvider} from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";

import App from "./App";
import "./styles/styles.scss";

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <AuthContainer>
          <ThemeContainer>
            <App />
          </ThemeContainer>
        </AuthContainer>
      </BrowserRouter>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
